.apa-mobile-nav {
	margin-bottom: 0;
	background-color: transparent;
	@include breakpoint($max300) {
		.navbar-brand{
			width: 40px;
			overflow: hidden;
			img{
				max-width: none;
			}
		}
	}
	.navbar-brand {
		padding: 14px 15px;
		img {

			height: 23px;
			@include breakpoint($min400) {
				height: 32px;
			}

		}
	}
	.navbar-toggle .icon-bar {
		background-color: #fff;
	}
	a {
		color: #fff;
		.nav-icon {
			display: inline-block;
			width: 40px;
			text-align: center;
		}
	}
	.mobile-lang-switch {
		float: right;
		@extend .clearfix;
		a {
			height: 50px;
			margin-right: 2px;
			width: 50px;
			float: left;
			@include font-size(14);
			font-weight: 700;
			display: block;
			text-align: center;
			line-height: 50px;
			&.active {
				color: lighten($text_c, 30%);
			}
		}
	}
	.nav-caret {
		text-align: center;
		width: 50px;
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 20px;
		&:before {
			content: "\f067";

		}
	}
	.dropdown-menu {
		padding: 0;
		a {

			color: #fff;
			@include font-size(16);
		}
	}
	.nav > li {
		& > a {
			padding-top: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid lighten($text_c, 6%);

		}
		&:last-child > a {
			border-bottom: none;
		}
	}
	.nav > li > a:focus, .nav > li > a:hover {
		background-color: $red_c;
	}
	.navbar-nav .open .dropdown-menu {
		background-color: lighten($text_c, 8%);
	}
	.navbar-nav .open .dropdown-menu > li {
		display: block;
		& > a {
			padding: 10px 10px 10px 60px;

			border-bottom: 1px solid lighten($text_c, 10%);
		}
	}
}