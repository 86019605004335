.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {

	color: #fff;
	background-color: $red_c;
	border-color: $red_c;
	cursor: default;
}
.pagination>li>a, .pagination>li>span{
	color: $text_c;
}




.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {



	color: #fff;
	background-color: $text_c;
	border-color: $text_c;
}