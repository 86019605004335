.wysiwyg-content {
    h1, h2, h3, h4, h5, h6{
        margin-bottom: 15px;
    }
    p {
        margin-top: 0;
        margin-bottom: 15px;
        img{
            display: inline-block;
        }
    }
}
