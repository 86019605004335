
.blog-prev {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	margin-bottom: 30px;
}

.blog-prev__link {
	text-decoration: none;
	color: $text_c;
	&:hover {
		@include breakpoint($min768) {
			.img-mask-wrap {
				right: -30px;
			}
			.blog-prev__text {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}

.blog-prev__title {
	margin-top: 30px;
	margin-bottom: 10px;
	@include font-size(16);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(20);
	}
	@include breakpoint($min1200) {
		@include font-size(24);
	}
}

.blog-prev__body {
	@extend .clearfix;
}

.blog-prev__img,
.blog-prev__text {
	float: left;
}

.blog-prev__text {
	height: 150px;
	padding: 0px 60px 60px 0px;
	@include breakpoint($max480) {
		padding: 0px 20px 20px 0px;
	}
	background-color: #fff;

	width: calc(100% - 160px);
	transition: $blog_transition;
	@include breakpoint($min992) {
		width: calc(100% - 180px);
		height: 250px;
	}
	@include breakpoint($min1600) {
		width: calc(100% - 390px);
		height: 300px;
		padding: 0px 60px 60px 0px;
	}
}

.blog-prev__text-inner {
	overflow: hidden;
	height: 65px;
	@include breakpoint($max480) {
		display: none;
	}
	@include breakpoint($min992) {
		height: 90px;
	}
	@include breakpoint($min1600) {
		height: 110px;
	}
}

.blog-prev__decor {
	display: none;
	color: $red_c;
	text-align: right;
	font-size: 30px;
	@include breakpoint($min992) {
		display: block;
	}
	@include breakpoint($min1600) {
		padding-top: 10px;
	}

}

.blog-prev__img {
	height: 150px;
	width: 160px;
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	@include breakpoint($min992) {
		height: 250px;
		width: 180px;
	}
	@include breakpoint($min1600) {
		height: 300px;
		width: 390px;
	}

}

.img-mask-wrap {
	position: absolute;
	height: 100%;
	right: 0;
	z-index: 50;
	width: 50px;
	transition: $blog_transition;
	&:before {
		content: '';
		width: 30px;
		height: 100%;
		background-color: $img_mask_color;
		position: absolute;
		right: 0;
	}
	@include breakpoint($min992) {
		width: 60px;
		&:before {
			width: 30px;
		}
	}
	@include breakpoint($min1600) {
		width: 90px;
		&:before {
			width: 30px;
		}
	}
}

.img-mask {
	position: relative;
	width: 20px;
	height: 100%;
	.triangle-mask {
		position: absolute;
	}
	.triangle-topright {
		top: 50%;
		margin-top: -20px;
		width: 0;
		height: 0;
		border-top: 20px solid $img_mask_color;
		border-left: 20px solid transparent;
	}
	.triangle-bottomright {
		bottom: 50%;
		margin-bottom: -20px;
		width: 0;
		height: 0;
		border-bottom: 20px solid $img_mask_color;
		border-left: 20px solid transparent;
	}
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		height: 55px;
		width: 20px;
		background-color: $img_mask_color;
		//background-color: red;
	}
	&:before {
		top: 0;
	}
	&:after {
		bottom: 0;
	}
	@include breakpoint($min992) {
		width: 30px;
		.triangle-topright {
			margin-top: -30px;
			border-top: 30px solid $img_mask_color;
			border-left: 30px solid transparent;
		}
		.triangle-bottomright {
			margin-bottom: -30px;
			border-bottom: 30px solid $img_mask_color;
			border-left: 30px solid transparent;
		}
		&:before,
		&:after {
			height: 95px;
			width: 30px;
		}
	}
	@include breakpoint($min1600) {
		width: 60px;
		.triangle-topright {
			margin-top: -60px;
			border-top: 60px solid $img_mask_color;
			border-left: 60px solid transparent;
		}
		.triangle-bottomright {
			margin-bottom: -60px;
			border-bottom: 60px solid $img_mask_color;
			border-left: 60px solid transparent;
		}
		&:before,
		&:after {
			height: 90px;
			width: 60px;
		}
	}
}

.blog-meta {
	.media-left {
		padding-right: 20px;
		.media-object {
			width: 150px;

			background-color: #fff;

			border: 1px solid #cecece;
		}
	}
	.media-body {
		overflow: visible;
		position: relative;
		width: auto;
		vertical-align: middle;
		padding: 0 40px;
		border: 1px solid #cecece;
		.autor-opis-arrow-left-1,
		.autor-opis-arrow-left-2 {
			width: 0;
			height: 0;
			position: absolute;
		}
		.autor-opis-arrow-left-1 {
			border-top: 16px solid transparent;
			border-bottom: 16px solid transparent;
			border-right: 16px solid #cecece;
			top: 20px;
			left: -16px;
			z-index: 5;
		}
		.autor-opis-arrow-left-2 {
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-right: 15px solid #fff;
			top: 21px;
			left: -14px;
			z-index: 10;
		}
	}
	.blog-meta-text {
		//padding: 15px;
		//border: 1px solid #cecece;
		.author-job {
			font-weight: 700;
			opacity: .7;
		}
		.author-edu {
			opacity: .7;
		}
		p {
			margin: 0;
		}
	}
}

.blog-prev__date {
	text-align: center;
	background-color: $red_c;
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 80px;
	height: 80px;
	border-bottom-right-radius: 40px;
	padding: 18px 9px 0 0px;
}

.page__blog-single {
	.blog-prev__date {
		padding-top: 15px;
		height: 95px;
	}
}

.blog-prev__day {
	line-height: 1;
	@include font-size(24);
	font-weight: 900;
}

.blog-prev__year {
	margin-top: 5px;
	line-height: 1;
	@include font-size(14);
	font-weight: 900;
}

.blog-prev__author {
	position: absolute;
	bottom: 0;
	left: 0;
	border-top-right-radius: 15px;
	background-color: $text_c;
	color: #fff;
	line-height: 15px;
	@include font-size(12);
	padding: 3px 15px 3px 6px;
}