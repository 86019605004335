.form-control {
	height: 46px;
	padding: 10px 12px;
	option {
		line-height: 32px;
		font-size: 18px;
	}
}

.upitnik-group {

}

.upitnik-header {
	padding: 20px;
}

.upitnik-body {
	padding: 20px;
	background-color: #efefef;
	label {
		//color: #fff;
	}
}

label {
	font-family: $font_primary;
}

.in-form-desc {
	opacity: .6;
}

.form-desc {
	opacity: .8;
}