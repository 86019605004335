/***
====================================================================
	Fonts
====================================================================
 ***/
//$font_primary: 'Muli', sans-serif;
$font_primary:  'Raleway', sans-serif;

$font_seocnd: 'Istok Web', sans-serif;


/***
====================================================================
	Colors
====================================================================
 ***/

$gray_c: #808184;
$red_c: #be212f;
//$text_c: #231f20;
$text_c: #333;

$img_mask_color: #fff;

/***
====================================================================
	Margine
====================================================================
 ***/
$page_sec_mrg:60px;




/***
====================================================================
	transition
====================================================================
 ***/
$blog_transition: all .3s ease-out;
/***
====================================================================
	clearfix
====================================================================
 ***/
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
@for $i from 1 through 10 {
	.spacer#{$i}0 {
		height: #{$i}0px;
	}
}

/***
====================================================================
media queries for breakpoint
====================================================================
 ***/
$min300: 300px;
$max300: max-width 299px;
$min400: 400px;
$max400: max-width 399px;
$min480: 480px;
$max480: max-width 479px;
$min768: 768px;
$max768: max-width 767px;
$min992: 992px;
$max992: max-width 991px;
$min1200: 1200px;
$max1200: max-width 1199px;
$min1400: 1400px;
$max1400: max-width 1399px;
$min1600: 1600px;
$max1600: max-width 1599px;
/*
@include breakpoint($min400) {
	font-size: 14px;
}
*/
/***
====================================================================
	Mixins for Rem Font Sizing
====================================================================
 ***/

@mixin font-size($sizeValue: 16) {
	font-size: $sizeValue  + px;
	font-size: ($sizeValue / 10) + rem;
}
/*
@include font-size(16);
 */

/***
====================================================================
	Tim text size
====================================================================
 ***/

$tim_text_height_1:78px;
$tim_text_height_2:100px;