.page__namenska-resenja{

    .page-sec__title {
        line-height: 1.2;
        text-align: left;
        @include font-size(20);
        font-weight: 300;
        @include breakpoint($min992) {
            @include font-size(40);
        }
    }
}