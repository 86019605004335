.full-bg-wrap {
	width: 100%;

	position: relative;
	&.full-height {
		height: 100vh;
		@include breakpoint($max768) {
			background-color: #000;
		}
	}
}

.full-bg__img,
.full-bg__overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.full-bg__text {
	position: relative;
	z-index: 10;
}

.full-bg__img {
	z-index: 1;
	background-repeat: no-repeat;
	background-size: cover;
	&.full-bg__img-fixed {
		background-attachment: fixed;
	}
}

.full-bg__overlay {
	z-index: 2;
	background: url("../img/slider-patttern.png");
}

.home-intro-sec {
	.full-bg__img {
		background-image: url("../img/full-bg-1.jpg");
		@include breakpoint($max768) {
			background-image: url("../img/full-bg-2.jpg");
			opacity: .3;
		}
	}
}

.home-about-sec {
	color: #fff;
	background-color: $text_c;
	.full-bg__img {
		opacity: .3;
		background-image: url("../img/home-about-2.jpg");
	}
}

.home-process-sec {
	color: $text_c;
	background-color: #fff;
	.full-bg__overlay{
		opacity: .3;
	}
	.full-bg__img {
		opacity: .08;
		background-image: url("../img/home-about-2.jpg");

	}
}

.call-to-action-idea-sec {
	background-color: $text_c;
	.full-bg__img {
		opacity: .3;
		background-image: url("../img/cta-bg-1.jpg");
	}
}

.contact-info-sec{
	background-color: $red_c;
	.full-bg__img {
		opacity: .15;
		background-image: url("../img/cta-bg-1.jpg");
	}
}


