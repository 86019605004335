.page-intro-sec {
    .full-bg-wrap {
        height: auto;
		@include breakpoint($min768) {
			height: 180px;
		}
		@include breakpoint($min992) {
			height: 340px;
		}
    }
    .full-bg__img {
        background-image: url(../img/full-bg-1.jpg);
    }
}

.page-intro__logo {
	padding: 20px;
	position: relative;
    z-index: 60;
    //width: 50%;
	margin-right: 100px;
	display: none;
    //a {
    //    display: block;
    //}
    @include breakpoint($min400) {

    }
    @include breakpoint($min768) {


    }
    @include breakpoint($min992) {
		display: block;
		position: absolute;

		top: 20px;
		left: 0;
		padding:30px 60px;
		background-color: rgba($text_c, .9);
    }
    @include breakpoint($min1200) {

    }
    @include breakpoint($min1600) {

    }
}

.page-intro__text {
	padding: 60px 20px 20px 20px;
	position: relative;


	@include breakpoint($min992) {
		position: absolute;
		bottom: 30px;
		//top: 200px;
		padding: 0 20px 0 60px;
	}
    width: 100%;

    z-index: 50;
    .page-type_name{
        @include font-size(32);
        font-weight: 300;
    }
}
