h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-family: $font_primary;
}

.shadow-hard {
	text-shadow: 2px 2px 0 #000;
}

.white-text {
	color: #fff;

}

.text-xxl {

}

.text-xl {
	@include font-size(24);
	font-weight: 700;
}

.home-intro__title {
	@include font-size(24);
	font-weight: 700;
	line-height: 1.2;
	text-align: center;
	span {
		display: block;
	}
	@include breakpoint($min400) {
		@include font-size(32);
	}
	@include breakpoint($min768) {
		@include font-size(60);
	}
	@include breakpoint($min992) {
		@include font-size(80);
	}
	@include breakpoint($min1200) {
		@include font-size(110);
	}
	@include breakpoint($min1400) {
		@include font-size(120);
	}
	@include breakpoint($min1600) {
		@include font-size(150);
	}
}

.page-intro__title {
	@include font-size(24);
	font-weight: 300;
	line-height: 1.2;

	span {
		//display: block;
	}
	@include breakpoint($min400) {
		@include font-size(24);
	}
	@include breakpoint($min768) {
		@include font-size(28);
	}
	@include breakpoint($min992) {
		@include font-size(32);
	}
	@include breakpoint($min1200) {
		@include font-size(40);
	}
	@include breakpoint($min1400) {
		@include font-size(50);
	}
	@include breakpoint($min1600) {
		@include font-size(60);
	}
}

.home-sec__title {
	line-height: 1.2;
	text-align: center;
	@include font-size(30);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(60);
	}
}

.home-sec__sub-title {
	text-align: center;
	@include font-size(20);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(32);
	}
}

.page-sec__title {
	line-height: 1.2;
	text-align: center;
	@include font-size(30);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(60);
	}
	&.left{
		text-align: left;
	}
}

.page-sec__sub-title {
	text-align: center;
	@include font-size(20);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(32);

	}
}

.page-sub-sec__title {
	line-height: 1.2;

	@include font-size(24);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(40);
	}

}

.page-sub-sec__sub-title {
	@include font-size(18);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(21);
		font-style: italic;
		margin-top: 1rem;
	}
}

.blog__title {
	line-height: 1.2;
	text-align: left;
	@include font-size(20);
	font-weight: 300;
	@include breakpoint($min992) {
		@include font-size(40);
	}
}