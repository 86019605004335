.btn {
	@include font-size(18);
	font-weight: 700;
	text-transform: uppercase;
	 white-space: normal;
}

.btn-bordered {
	background-color: transparent;
	border: 3px solid $text_c;
	color: $text_c;
	&.btn-primary {
		border-color: $red_c;
		color: $red_c;
		&:hover,
		&:focus{
			background-color: $red_c;
			color: #fff;
		}
	}
	&.btn-white{
		border-color: #fff;
		color: #fff;
		&:hover,
		&:focus{
			background-color: #fff;
			color: $red_c;
		}
	}
	&:hover,
	&:focus{
		background-color: $text_c;
		color: #fff;
	}
}

.btn-primary {

}

.btn-namensko{
	background-color: transparent;
	border: 3px solid $red_c;
	@include font-size(16);
	font-weight: 700;
	text-transform: uppercase;

	color: $red_c;
	&:hover,
	&:focus{
		background-color: $red_c;
		color: #fff;
	}
}

.btn-scroll-top {
	position: fixed;
	bottom: 0;
	right: 0;
	@include breakpoint($min768) {
		right: 80px;
	}
	display: block;
	visibility: hidden;
	cursor: pointer;
	z-index: 999;
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 50px;


	opacity: 0;
	transition: all .35s ease-in-out;
	-webkit-transition: all .35s ease-in-out;
	text-align: center;
	color: $red_c;
	&.fixed {
		opacity: 1;
		bottom: 26px;
		visibility: visible;
	}
}
