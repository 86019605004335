.tim-list {
	@extend .clearfix;
}

.tim-single {

	float: left;
	width: 50%;

	@include breakpoint($min768) {

		width: 33.33%;
	}
	@include breakpoint($min992) {
		width: 50%;
	}

	@include breakpoint($min1400) {
		width: 33.33%;
	}
	padding: 20px;
	.inner {
		position: relative;
		//background-color: red;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
		padding-bottom: $tim_text_height_1;
		@include breakpoint($min400) {
			padding-bottom: $tim_text_height_2;
		}
		@include breakpoint($min768) {
			padding-bottom: $tim_text_height_1;
		}
		@include breakpoint($min992) {
			padding-bottom: $tim_text_height_2;
		}

		@include breakpoint($min1200) {
			padding-bottom: $tim_text_height_1;
		}
		@include breakpoint($min1400) {
			padding-bottom: $tim_text_height_2;
		}
		@include breakpoint($min1600) {
			padding-bottom: $tim_text_height_1;
		}

	}

	&.last {

		.tim-single__text {
			height: auto;
			//background-color: #2aabd2;

		}
		.tim-single__job {
			display: block;
		}
		@include breakpoint($max480) {

			width: 100%;

			.tim-single__text {
				//position: static;

			}
		}
	}
}

.tim-single__text {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	height: $tim_text_height_1;

	@include breakpoint($min400) {
		height: $tim_text_height_2;
	}
	@include breakpoint($min768) {
		height: $tim_text_height_1;
	}
	@include breakpoint($min992) {
		height: $tim_text_height_2;
	}

	@include breakpoint($min1200) {
		height: $tim_text_height_1;
	}
	@include breakpoint($min1400) {
		height: $tim_text_height_2;
	}
	@include breakpoint($min1600) {
		height: $tim_text_height_1;
	}
}

.tim-single__img {
	img{
		margin: 0 auto;
	}
	position: relative;
	.job-btn-wrap {
		position: absolute;
		text-align: center;
		height: 50px;
		width: 100%;
		bottom: 20px;
	}
}

.tim-single__name {
	padding: 15px 15px 2px 15px;
}

.tim-single__job {
	display: none;
	@include breakpoint($min400) {
		display: block;
	}
	padding: 2px 15px 15px 15px;
	font-weight: 700;
}