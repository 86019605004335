@charset "UTF-8";
/***
====================================================================
	Fonts
====================================================================
 ***/
/***
====================================================================
	Colors
====================================================================
 ***/
/***
====================================================================
	Margine
====================================================================
 ***/
/***
====================================================================
	transition
====================================================================
 ***/
/***
====================================================================
	clearfix
====================================================================
 ***/
.clearfix:after, .sidebar-nav li a:after, .page-social__links:after, .lang-switch:after, .apa-mobile-nav .mobile-lang-switch:after, .footer-nav__wrap:after, .footer-soc:after, .address-list:after, .proces-prev-wrap:after, .blog-prev__body:after, .tim-list:after {
  content: "";
  display: table;
  clear: both; }

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
.spacer10 {
  height: 10px; }

.spacer20 {
  height: 20px; }

.spacer30 {
  height: 30px; }

.spacer40 {
  height: 40px; }

.spacer50 {
  height: 50px; }

.spacer60 {
  height: 60px; }

.spacer70 {
  height: 70px; }

.spacer80 {
  height: 80px; }

.spacer90 {
  height: 90px; }

.spacer100 {
  height: 100px; }

/***
====================================================================
media queries for breakpoint
====================================================================
 ***/
/*
@include breakpoint($min400) {
	font-size: 14px;
}
*/
/***
====================================================================
	Mixins for Rem Font Sizing
====================================================================
 ***/
/*
@include font-size(16);
 */
/***
====================================================================
	Tim text size
====================================================================
 ***/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8); }

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0; }

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

html {
  min-height: 100%;
  font-size: 62.5%; }

body {
  font-family: "Istok Web", sans-serif;
  color: #333;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400; }
  body img {
    max-width: 100%;
    display: block;
    height: auto; }
  body .media-object {
    max-width: none; }

a {
  color: #be212f; }
  a:hover, a:focus {
    color: #333; }

#map {
  height: 440px;
  width: 100%; }

button {
  padding: 0;
  margin: 0;
  border: none;
  outline: 0;
  background: 0 0; }

.list-none {
  margin: 0;
  padding: 0;
  list-style: none; }

.container-both-pd {
  padding-right: 30px;
  padding-left: 60px; }

.white-popup-block {
  background: #FFF;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative; }
  .white-popup-block .modal-header {
    padding: 20px;
    position: relative; }
  .white-popup-block .modal-title {
    margin: 0; }
  .white-popup-block .popup-modal-dismiss {
    font-size: 36px;
    font-size: 3.6rem;
    position: absolute;
    top: 8px;
    right: 20px;
    z-index: 20; }

.min-height-50 {
  min-height: 50px; }

.apaone-word {
  font-weight: 700; }
  .apaone-word .w-apa {
    color: gray; }
  .apaone-word .w-one {
    color: #be212f; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: "Raleway", sans-serif; }

.shadow-hard {
  text-shadow: 2px 2px 0 #000; }

.white-text {
  color: #fff; }

.text-xl {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700; }

.home-intro__title {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center; }
  .home-intro__title span {
    display: block; }
  @media (min-width: 400px) {
    .home-intro__title {
      font-size: 32px;
      font-size: 3.2rem; } }
  @media (min-width: 768px) {
    .home-intro__title {
      font-size: 60px;
      font-size: 6rem; } }
  @media (min-width: 992px) {
    .home-intro__title {
      font-size: 80px;
      font-size: 8rem; } }
  @media (min-width: 1200px) {
    .home-intro__title {
      font-size: 110px;
      font-size: 11rem; } }
  @media (min-width: 1400px) {
    .home-intro__title {
      font-size: 120px;
      font-size: 12rem; } }
  @media (min-width: 1600px) {
    .home-intro__title {
      font-size: 150px;
      font-size: 15rem; } }

.page-intro__title {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 400px) {
    .page-intro__title {
      font-size: 24px;
      font-size: 2.4rem; } }
  @media (min-width: 768px) {
    .page-intro__title {
      font-size: 28px;
      font-size: 2.8rem; } }
  @media (min-width: 992px) {
    .page-intro__title {
      font-size: 32px;
      font-size: 3.2rem; } }
  @media (min-width: 1200px) {
    .page-intro__title {
      font-size: 40px;
      font-size: 4rem; } }
  @media (min-width: 1400px) {
    .page-intro__title {
      font-size: 50px;
      font-size: 5rem; } }
  @media (min-width: 1600px) {
    .page-intro__title {
      font-size: 60px;
      font-size: 6rem; } }

.home-sec__title {
  line-height: 1.2;
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .home-sec__title {
      font-size: 60px;
      font-size: 6rem; } }

.home-sec__sub-title {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .home-sec__sub-title {
      font-size: 32px;
      font-size: 3.2rem; } }

.page-sec__title {
  line-height: 1.2;
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .page-sec__title {
      font-size: 60px;
      font-size: 6rem; } }
  .page-sec__title.left {
    text-align: left; }

.page-sec__sub-title {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .page-sec__sub-title {
      font-size: 32px;
      font-size: 3.2rem; } }

.page-sub-sec__title {
  line-height: 1.2;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .page-sub-sec__title {
      font-size: 40px;
      font-size: 4rem; } }

.page-sub-sec__sub-title {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .page-sub-sec__sub-title {
      font-size: 21px;
      font-size: 2.1rem;
      font-style: italic;
      margin-top: 1rem; } }

.blog__title {
  line-height: 1.2;
  text-align: left;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .blog__title {
      font-size: 40px;
      font-size: 4rem; } }

body {
  position: relative;
  overflow-x: hidden; }

body,
html {
  height: 100%; }

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent; }

/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

#sidebar-wrapper {
  display: none;
  z-index: 1000;
  left: auto;
  right: -200px;
  width: 60px;
  width: 260px;
  height: 100%;
  margin-left: -220px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #1a1a1a;
  background: #be212f;
  transition: all 0.5s ease;
  border: none; }
  @media (min-width: 992px) {
    #sidebar-wrapper {
      display: block; } }
  #sidebar-wrapper.is-open {
    height: 100%;
    min-height: 480px;
    position: absolute;
    position: fixed; }
  #sidebar-wrapper:hover, #sidebar-wrapper:focus {
    width: 280px;
    right: 0; }

#sidebar-wrapper::-webkit-scrollbar {
  display: none; }

#page-content-wrapper {
  width: 100%;
  padding-top: 70px; }

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -220px; }

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
#sidebar-wrapper .nav-right-menu {
  background-color: #be212f;
  width: 100%;
  position: absolute;
  z-index: 70;
  top: 80px;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none; }
  #sidebar-wrapper .nav-right-menu .dropdown-menu > li > a {
    white-space: normal; }

#sidebar-wrapper.is-open .nav-right-menu {
  left: 0; }

.sidebar-nav li {
  position: relative;
  line-height: 20px;
  display: block;
  width: 100%; }

@media (min-width: 768px) {
  .sidebar-nav li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 0px;
    background-color: #1c1c1c;
    transition: all .2s ease-in-out;
    opacity: 0; }
  .sidebar-nav li:hover:before,
  .sidebar-nav li.open:hover:before {
    opacity: 1;
    width: 100%; }
  .sidebar-nav li a:hover,
  .sidebar-nav li a:active,
  .sidebar-nav li a:focus,
  .sidebar-nav li.open a:hover,
  .sidebar-nav li.open a:active,
  .sidebar-nav li.open a:focus {
    color: #fff;
    text-decoration: none;
    background-color: transparent; } }

.sidebar-nav li a {
  font-family: "Istok Web", sans-serif;
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 12px 0 12px 0;
  border-bottom: 1px solid #d92635; }
  .sidebar-nav li a span {
    float: left; }
  .sidebar-nav li a .nav-icon {
    text-align: center;
    width: 60px; }
    .sidebar-nav li a .nav-icon.lang-nav-icon {
      font-weight: 900; }
  .sidebar-nav li a .nav-link-text {
    width: 160px;
    font-size: 13px;
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .sidebar-nav li a .nav-caret {
    text-align: center;
    width: 35px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 20px; }
    .sidebar-nav li a .nav-caret:before {
      content: "\f067"; }

.sidebar-nav > li.active > a {
  background-color: #1a1a1a; }

.sidebar-nav > li.activelang > a {
  color: #ef3b4a;
  background-color: #be212f; }

.dropdown.open .nav-caret:before {
  content: "\f068"; }

.sidebar-nav li.open {
  background-color: #1a1a1a; }

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 20px;
  line-height: 44px; }

.sidebar-nav .dropdown-menu {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #3e3e3e;
  box-shadow: none;
  float: none; }
  .sidebar-nav .dropdown-menu a {
    padding-left: 60px;
    border-bottom: 1px solid #4f4f4f; }
  .sidebar-nav .dropdown-menu li.active a {
    background-color: #676767; }

/*-------------------------------*/
/*     nav-right-menu-mobile     */
/*-------------------------------*/
#sidebar-wrapper #nav-right-menu-mobile {
  background-color: #be212f;
  position: absolute;
  z-index: 70;
  top: 70px;
  left: 60px;
  width: 220px;
  margin: 0;
  padding: 0;
  list-style: none; }
  #sidebar-wrapper #nav-right-menu-mobile .dropdown-menu > li > a {
    white-space: normal; }
  #sidebar-wrapper #nav-right-menu-mobile.nav > li > a:focus,
  #sidebar-wrapper #nav-right-menu-mobile.nav > li > a:hover {
    background-color: inherit;
    outline: none; }

#sidebar-wrapper.is-open #nav-right-menu-mobile {
  left: 0; }

#sidebar-wrapper.is-closed #nav-right-menu-mobile {
  opacity: 0; }

#sidebar-wrapper .page-social__links-mobile a {
  display: inline-block;
  width: 33px;
  text-align: center;
  padding: 10px 0; }

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/
.hamburger {
  position: relative;
  right: 0;
  top: 0;
  z-index: 999;
  display: block;
  width: 100%;
  height: 60px;
  background: #e52737;
  border: none; }
  @media (min-width: 768px) {
    .hamburger {
      height: 80px; } }
  .hamburger #hamburger-hamb-wrap {
    position: absolute;
    left: 15px;
    top: 15px;
    display: block;
    width: 30px;
    height: 32px;
    transition: all .35s ease-in-out; }
    @media (min-width: 768px) {
      .hamburger #hamburger-hamb-wrap {
        top: 25px; } }
  .hamburger.is-open {
    right: 0; }
    .hamburger.is-open #hamburger-hamb-wrap {
      right: 92px; }

.hamburger-img {
  width: 260px;
  padding-left: 80px;
  padding-right: 30px;
  padding-top: 25px; }

.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none; }

.hamburger.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transition: all .35s ease-in-out; }

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%; }

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #fff; }

.hamburger.is-closed .hamb-top {
  top: 5px;
  -webkit-transition: all .35s ease-in-out; }

.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px; }

.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  -webkit-transition: all .35s ease-in-out; }

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #fff; }

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px; }

.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08); }

.hamburger.is-open .hamb-middle {
  display: none; }

.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08); }

.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all .35s ease-in-out; }

.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  transition: all .35s ease-in-out; }

/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.8);
  z-index: 191; }

/*
page-social
***********************************************************************************************************************/
.page-social-wrap {
  position: static;
  padding: 20px 0;
  width: 100%;
  z-index: 60;
  text-align: center; }
  @media (min-width: 768px) {
    .page-social-wrap {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0; } }
  .page-social-wrap a {
    display: block;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    padding: 5px 0; }
    .page-social-wrap a:hover, .page-social-wrap a:focus {
      background-color: #333; }
  @media (min-width: 768px) {
    .page-social-wrap {
      display: block; } }

.page-social__inner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 80; }

.page-social__links {
  position: relative;
  width: 100%;
  text-align: center; }
  .page-social__links a {
    position: absolute;
    width: 60px;
    display: inline-block;
    transition: all .2s ease-in; }
    .page-social__links a.behance {
      left: 0;
      bottom: 175px; }
    .page-social__links a.youtube {
      left: 0;
      bottom: 140px; }
    .page-social__links a.linkedin {
      left: 0;
      bottom: 105px; }
    .page-social__links a.google {
      left: 0;
      bottom: 70px; }
    .page-social__links a.twitter {
      left: 0;
      bottom: 35px; }
    .page-social__links a.facebook {
      left: 0;
      bottom: 0; }

#wrapper.toggled .page-social__links {
  padding: 0 25px; }
  #wrapper.toggled .page-social__links a {
    width: 36px; }
    #wrapper.toggled .page-social__links a.behance {
      left: 0;
      bottom: 0; }
    #wrapper.toggled .page-social__links a.youtube {
      left: 36px;
      bottom: 0; }
    #wrapper.toggled .page-social__links a.linkedin {
      left: 72px;
      bottom: 0; }
    #wrapper.toggled .page-social__links a.google {
      left: 108px;
      bottom: 0; }
    #wrapper.toggled .page-social__links a.twitter {
      left: 144px;
      bottom: 0; }
    #wrapper.toggled .page-social__links a.facebook {
      left: 180px;
      bottom: 0; }

.nav-right-menu, .page-social-wrap {
  display: none; }
  @media (min-width: 768px) {
    .nav-right-menu, .page-social-wrap {
      display: block; } }

.nav-right-menu-mobile {
  display: block; }
  @media (min-width: 768px) {
    .nav-right-menu-mobile {
      display: none; } }

.lang-switch {
  width: 60px;
  background-color: #be212f;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 50; }
  .lang-switch .lang {
    display: block;
    width: 60px;
    height: 40px;
    text-transform: uppercase;
    float: left;
    line-height: 40px;
    text-align: center;
    color: #333;
    font-weight: 700; }
    .lang-switch .lang.active {
      color: #fff; }
      .lang-switch .lang.active:hover, .lang-switch .lang.active.focus {
        background-color: transparent; }
    .lang-switch .lang:hover, .lang-switch .lang.focus {
      background-color: #333;
      color: #fff;
      text-decoration: none; }
  .lang-switch.lang-switch-mobile {
    display: block; }
    @media (min-width: 768px) {
      .lang-switch.lang-switch-mobile {
        display: none; } }
  .lang-switch.lang-switch-desk {
    display: none; }
    @media (min-width: 768px) {
      .lang-switch.lang-switch-desk {
        display: block;
        top: 80px; } }

.page-social__left-standalone {
  display: none; }
  @media (min-width: 992px) {
    .page-social__left-standalone {
      display: block;
      position: fixed;
      left: 0;
      top: 50%;
      z-index: 1000; } }
  .page-social__left-standalone a {
    background-color: #be212f;
    display: block;
    width: 40px;
    height: 40px;
    margin-bottom: 2px;
    text-align: center;
    padding: 10px 0;
    font-size: 20px;
    font-size: 2rem;
    color: #fff; }
    .page-social__left-standalone a .fa {
      display: block; }
    .page-social__left-standalone a:hover, .page-social__left-standalone a:focus {
      text-decoration: none;
      background-color: #333; }

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  border-color: transparent; }

#mobile-nav {
  position: absolute;
  width: 100%;
  border: none;
  z-index: 200; }
  #mobile-nav .mobile-nav-inner {
    position: relative; }
  #mobile-nav .navbar-header {
    position: absolute;
    width: 100%;
    z-index: 200; }
  #mobile-nav #mobile-nav-menu {
    margin: 0;
    padding-top: 60px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: #333;
    right: -110%;
    transition: all 0.2s ease-in-out; }
    #mobile-nav #mobile-nav-menu.show_in {
      right: 0; }

.apa-mobile-nav {
  margin-bottom: 0;
  background-color: transparent; }
  @media (max-width: 299px) {
    .apa-mobile-nav .navbar-brand {
      width: 40px;
      overflow: hidden; }
      .apa-mobile-nav .navbar-brand img {
        max-width: none; } }
  .apa-mobile-nav .navbar-brand {
    padding: 14px 15px; }
    .apa-mobile-nav .navbar-brand img {
      height: 23px; }
      @media (min-width: 400px) {
        .apa-mobile-nav .navbar-brand img {
          height: 32px; } }
  .apa-mobile-nav .navbar-toggle .icon-bar {
    background-color: #fff; }
  .apa-mobile-nav a {
    color: #fff; }
    .apa-mobile-nav a .nav-icon {
      display: inline-block;
      width: 40px;
      text-align: center; }
  .apa-mobile-nav .mobile-lang-switch {
    float: right; }
    .apa-mobile-nav .mobile-lang-switch a {
      height: 50px;
      margin-right: 2px;
      width: 50px;
      float: left;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: 700;
      display: block;
      text-align: center;
      line-height: 50px; }
      .apa-mobile-nav .mobile-lang-switch a.active {
        color: gray; }
  .apa-mobile-nav .nav-caret {
    text-align: center;
    width: 50px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 20px; }
    .apa-mobile-nav .nav-caret:before {
      content: "\f067"; }
  .apa-mobile-nav .dropdown-menu {
    padding: 0; }
    .apa-mobile-nav .dropdown-menu a {
      color: #fff;
      font-size: 16px;
      font-size: 1.6rem; }
  .apa-mobile-nav .nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #424242; }
  .apa-mobile-nav .nav > li:last-child > a {
    border-bottom: none; }
  .apa-mobile-nav .nav > li > a:focus, .apa-mobile-nav .nav > li > a:hover {
    background-color: #be212f; }
  .apa-mobile-nav .navbar-nav .open .dropdown-menu {
    background-color: #474747; }
  .apa-mobile-nav .navbar-nav .open .dropdown-menu > li {
    display: block; }
    .apa-mobile-nav .navbar-nav .open .dropdown-menu > li > a {
      padding: 10px 10px 10px 60px;
      border-bottom: 1px solid #4d4d4d; }

.page-footer {
  border-top: 3px solid #181818;
  background-color: #333;
  color: #fff;
  background-image: url("../img/page-bg-pattern-10.png"); }

/*
    Footer part -- footer-top
 **********************************************************************************************************************/
.footer-top {
  padding-top: 30px;
  padding-bottom: 30px; }

.footer__title {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.1; }

/*
    footer_col
 **********************************************************************************************************************/
.footer_col {
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 100%; }
  @media (min-width: 1200px) {
    .footer_col.footer_col-soc {
      float: none; } }

/*
    Footer part -- footer-top
    footer-newsletter
 **********************************************************************************************************************/
.footer-newsletter {
  margin-bottom: 20px; }

.footer-newsletter__form {
  position: relative; }
  .footer-newsletter__form input[type=text] {
    display: inline-block;
    height: 40px;
    font-size: 13px;
    background-color: #fff;
    width: 100%;
    padding: 10px 50px 10px 10px;
    color: #333; }
  .footer-newsletter__form button {
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #be212f;
    display: inline-block;
    height: 40px;
    font-size: 13px; }

/*
    Footer part -- footer-top
    footer-nav
 **********************************************************************************************************************/
.footer-nav__wrap {
  text-align: center; }

.footer-nav {
  list-style-type: square;
  color: #be212f;
  text-align: left;
  padding-left: 20px;
  margin: 0; }
  @media (min-width: 768px) {
    .footer-nav {
      display: block; } }
  .footer-nav li {
    float: left;
    width: 20%; }
    .footer-nav li a {
      color: #fff;
      font-size: 14px;
      font-size: 1.4rem; }

.mobile-footer-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }
  .mobile-footer-nav li {
    display: inline-block; }
    .mobile-footer-nav li:after {
      content: '|';
      padding: 0 10px;
      display: inline-block; }
    .mobile-footer-nav li:last-child:after {
      content: '';
      padding: 0; }
    .mobile-footer-nav li a {
      color: #fff;
      font-size: 14px;
      font-size: 1.4rem; }

/*
    Footer part -- footer-top
    footer-soc
 **********************************************************************************************************************/
.footer-soc {
  text-align: center; }
  .footer-soc a {
    width: 16.6%;
    display: block;
    float: left;
    color: #333;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    font-size: 2rem;
    text-shadow: 2px 2px 0 #be212f; }
    .footer-soc a:hover, .footer-soc a:focus {
      color: #262626; }
    @media (min-width: 400px) {
      .footer-soc a {
        height: 40px;
        line-height: 40px;
        font-size: 30px;
        font-size: 3rem;
        text-shadow: 3px 3px 0 #be212f; } }
    @media (min-width: 480px) {
      .footer-soc a {
        line-height: 60px;
        height: 60px;
        font-size: 50px;
        font-size: 5rem; } }
    @media (min-width: 1200px) {
      .footer-soc a {
        width: 33.3%;
        height: 140px;
        line-height: 140px;
        font-size: 140px;
        font-size: 14rem; } }

/*
    Footer part -- address-list
 **********************************************************************************************************************/
.address-list__wrap {
  background-color: #404040;
  padding: 8px 0;
  text-align: center;
  font-size: 0; }

.address-list {
  display: inline-block;
  line-height: 0; }
  .address-list li {
    font-size: 14px;
    font-size: 1.4rem;
    text-align: left;
    display: block;
    float: left; }
    .address-list li .fa {
      text-align: center;
      width: 20px;
      margin-right: 2px; }
    .address-list li span {
      display: inline-block; }
      .address-list li span a {
        color: #be212f; }
    .address-list li.address-list__text-wrap {
      line-height: 20px;
      padding: 9px 0;
      margin-right: 35px; }
    .address-list li.address-list__btn-wrap .btn {
      font-size: 14px;
      font-size: 1.4rem; }

/*
    Footer part -- footer-copy
 **********************************************************************************************************************/
.footer-copy {
  color: #797274;
  font-size: 14px;
  font-size: 1.4rem;
  background-color: #262626;
  background-image: url("../img/page-bg-pattern-10.png");
  padding: 10px 0; }
  @media (min-width: 768px) {
    .footer-copy {
      text-align: right; } }

.page-wrap {
  background-image: url("../img/page-bg-2.png");
  overflow: hidden;
  position: relative; }

@media (min-width: 992px) {
  .page-sec-mrg > .inner {
    margin-right: 60px;
    margin-left: 60px; } }

@media (min-width: 768px) {
  .page-sec-mrg > .inner .container {
    width: 690px; } }

@media (min-width: 992px) {
  .page-sec-mrg > .inner .container {
    width: 910px; } }

@media (min-width: 1200px) {
  .page-sec-mrg > .inner .container {
    width: 1110px; } }

@media (min-width: 992px) {
  .home-about-sec.page-sec-mrg > .inner,
  .home-process-sec.page-sec-mrg > .inner,
  .call-to-action-idea-sec.page-sec-mrg > .inner,
  .contact-info-sec.page-sec-mrg > .inner,
  .page-footer.page-sec-mrg > .inner {
    margin-left: 0; } }

.svg-wrap svg {
  max-width: 100%;
  height: auto; }
  .svg-wrap svg text {
    font-family: "Raleway", sans-serif;
    font-weight: 900; }

.page-sub-sec {
  padding-bottom: 20px; }
  @media (min-width: 768px) {
    .page-sub-sec {
      padding: 60px 40px; } }

.page-sec__header {
  margin-bottom: 30px; }
  .page-sec__header.center-page-sec__header {
    text-align: center; }

.page-sub-sec__header {
  margin-bottom: 30px; }

.namenska-resenja-content-sec {
  padding-bottom: 60px; }

/***********************************************************************************************************************
Single blog
***********************************************************************************************************************/
.page__blog-single .blog-sec__header,
.page__blog-single .wysiwyg-content {
  max-width: 800px; }

.blog-sec__header {
  padding-bottom: 20px; }
  .blog-sec__header .blog-img {
    position: relative;
    padding-bottom: 20px; }

.blog-meta a {
  color: #be212f;
  font-weight: 700; }

.disqus-wrap {
  padding: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px; }

.disqus-title {
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: left;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900; }
  @media (min-width: 992px) {
    .disqus-title {
      font-size: 30px;
      font-size: 3rem; } }

.blog-nav__title {
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: left;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900; }
  @media (min-width: 992px) {
    .blog-nav__title {
      font-size: 30px;
      font-size: 3rem; } }

.grid-sizer,
.grid-item {
  width: 100%; }

.grid-item--width2 {
  width: 100%; }

@media (min-width: 480px) {
  .grid-sizer,
  .grid-item {
    width: 50%; }
  .grid-item--width2 {
    width: 50%; } }

@media (min-width: 1200px) {
  .grid-sizer,
  .grid-item {
    width: 33.33%; }
  .grid-item--width2 {
    width: 30%; } }

.ref-item .inner {
  padding: 20px; }

@media (min-width: 992px) {
  a.ref-item__link:hover .ref-item__img img, a.ref-item__link:focus .ref-item__img img {
    opacity: .3; } }

@media (min-width: 992px) {
  a.ref-item__link:hover .ref-item__header, a.ref-item__link:focus .ref-item__header {
    left: 0; } }

.ref-item__img {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border: 1px solid #cecece; }
  .ref-item__img img {
    transition: .35s ease-in-out; }

.ref-item__header {
  background-color: #333;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  z-index: 5;
  transition: .35s ease-in-out; }
  @media (min-width: 1200px) {
    .ref-item__header {
      position: absolute;
      font-size: 16px;
      font-size: 1.6rem;
      padding: 20px;
      top: 50%;
      left: -100%; } }

.ref-item__title-short {
  display: block; }
  @media (min-width: 992px) {
    .ref-item__title-short {
      display: none; } }

.ref-item__title {
  display: none; }
  @media (min-width: 992px) {
    .ref-item__title {
      display: block; } }

.ref-img {
  margin-top: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px; }

/***********************************************************************************************************************
breadcrumb
***********************************************************************************************************************/
.breadcrumb-wrap {
  background-color: #f5f5f5;
  padding: 8px 15px; }
  .breadcrumb-wrap .breadcrumb {
    padding: 0;
    margin: 0; }

/***********************************************************************************************************************
About page
***********************************************************************************************************************/
.half-page-content {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 768px) {
    .half-page-content.left {
      padding-left: 40px; } }

.video-popup-link {
  display: inline-block;
  position: relative; }
  .video-popup-link .fa {
    position: absolute;
    top: 50%;
    margin-top: -40px;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    font-size: 80px;
    line-height: 80px;
    text-align: center;
    color: #be212f; }

/***********************************************************************************************************************
Contact page
***********************************************************************************************************************/
.contact-info-sec {
  background-color: rgba(190, 33, 47, 0.9);
  color: #fff; }
  @media (min-width: 768px) {
    .contact-info-sec .page-sub-sec {
      padding: 50px 40px 30px 40px; } }
  @media (max-width: 991px) {
    .contact-info-sec .page-sec__header {
      padding-top: 30px; } }
  .contact-info-sec .page-sub-sec__title {
    font-size: 32px;
    font-size: 3.2rem; }

.map-holder {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  background-color: #fff;
  padding: 10px; }
  .map-holder .map {
    border: 1px solid #dfdfdf; }

.contact-info__group {
  text-align: center; }
  .contact-info__group .inner {
    display: inline-block; }

.contact-info__col {
  text-align: left;
  display: block;
  margin-bottom: 40px; }
  @media (min-width: 480px) {
    .contact-info__col {
      display: inline-block;
      width: 40%; } }
  @media (min-width: 992px) {
    .contact-info__col {
      padding: 30px;
      margin-bottom: 0; } }
  @media (min-width: 1200px) {
    .contact-info__col {
      padding: 30px;
      width: auto;
      border-right: 1px solid rgba(255, 255, 255, 0.4); } }
  .contact-info__col:last-child {
    border-right: none; }
  .contact-info__col .fa {
    width: 26px;
    text-align: center;
    padding-right: 10px;
    color: rgba(255, 255, 255, 0.8); }
  .contact-info__col p {
    margin-bottom: 5px; }

.contact-info__header {
  height: 56px; }

.contact-info__title {
  font-size: 24px;
  font-size: 2.4rem; }

.contact-info__name {
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 7px; }

/***********************************************************************************************************************
Upitnik page
***********************************************************************************************************************/
.page-upitnik-sec {
  padding-bottom: 60px; }

/***********************************************************************************************************************
404 page
***********************************************************************************************************************/
.page__404__icon {
  text-align: center; }
  .page__404__icon img {
    display: inline-block; }

.text-404 {
  font-size: 24px;
  font-size: 2.4rem; }

/*
home-intro__text
***********************************************************************************************************************/
.home-intro__text {
  position: absolute;
  bottom: 10%;
  width: 100%;
  z-index: 50; }
  @media (min-width: 768px) {
    .home-intro__text {
      padding: 0 20px 0 50px; } }

.home-intro__logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 60;
  margin-right: 100px; }
  .home-intro__logo a {
    display: block; }
  @media (min-width: 768px) {
    .home-intro__logo {
      top: 20px;
      left: 0;
      padding: 30px 60px;
      background-color: rgba(51, 51, 51, 0.9); } }
  @media (max-width: 991px) {
    .home-intro__logo {
      display: none; } }

/*
home-services-sec
***********************************************************************************************************************/
.home-sec__header {
  padding: 15px 0; }

/*
home-process-sec
***********************************************************************************************************************/
.home-process-sec {
  margin: 80px 0 0 0; }
  .home-process-sec .home-sec__header {
    margin-bottom: 40px;
    margin-top: 30px; }

.proces-prev {
  width: 16.66%;
  position: relative;
  float: left;
  height: 560px; }

.proces-img {
  width: 130%;
  position: absolute;
  left: 0;
  top: 50%; }

.proces-body {
  position: absolute;
  width: 128%; }

.proces-title {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.31);
  font-size: 32px;
  font-size: 3.2rem; }

.proces-prev-1 .proces-body,
.proces-prev-3 .proces-body,
.proces-prev-5 .proces-body {
  top: 0; }

.proces-prev-1 .proces-img,
.proces-prev-3 .proces-img,
.proces-prev-5 .proces-img {
  transform: translate(0, -70%); }

.proces-prev-2 .proces-body,
.proces-prev-4 .proces-body,
.proces-prev-6 .proces-body {
  bottom: 0; }

.proces-prev-2 .proces-img,
.proces-prev-4 .proces-img,
.proces-prev-6 .proces-img {
  transform: translate(0, -30%); }

.proces-prev-1 .proces-title {
  color: #13637c; }

.proces-prev-2 .proces-title {
  color: #33b7c6; }

.proces-prev-3 .proces-title {
  color: #99cc33; }

.proces-prev-4 .proces-title {
  color: #ffab2e; }

.proces-prev-5 .proces-title {
  color: #ea4b48; }

.proces-prev-6 .proces-title {
  color: #a63573; }

/*
home-about-sec
***********************************************************************************************************************/
.home-about-sec {
  margin: 0 0 80px 0;
  color: #fff; }
  .home-about-sec .full-bg-wrap > .container {
    padding-bottom: 60px; }
  .home-about-sec .home-sec__header {
    margin-top: 30px;
    margin-bottom: 10px; }
  .home-about-sec .full-bg__text-col {
    font-weight: 300;
    font-size: 18px;
    font-size: 1.8rem; }

/*
home-blog-sec
***********************************************************************************************************************/
.page__namenska-resenja .page-sec__title {
  line-height: 1.2;
  text-align: left;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .page__namenska-resenja .page-sec__title {
      font-size: 40px;
      font-size: 4rem; } }

.services-single-content__col-icon {
  display: none; }
  @media (min-width: 768px) {
    .services-single-content__col-icon {
      display: block;
      padding: 80px 10px 10px 10px; } }
  @media (min-width: 992px) {
    .services-single-content__col-icon {
      padding: 80px 20px 20px 20px; } }
  @media (min-width: 1200px) {
    .services-single-content__col-icon {
      padding: 80px 40px 40px 40px; } }
  .services-single-content__col-icon .svg-wrap svg {
    width: 100%;
    height: auto; }
    .services-single-content__col-icon .svg-wrap svg .a {
      fill: rgba(51, 51, 51, 0.2); }

.services-single-content__col-text {
  position: relative;
  padding-top: 40px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .services-single-content__col-text {
      padding-top: 80px;
      padding-right: 80px; } }
  .services-single-content__col-text .page-sec__body {
    position: relative;
    z-index: 5; }

.single__icon-bg {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: .08;
  z-index: 1; }
  @media (min-width: 768px) {
    .single__icon-bg {
      display: none; } }

.wysiwyg-content h1, .wysiwyg-content h2, .wysiwyg-content h3, .wysiwyg-content h4, .wysiwyg-content h5, .wysiwyg-content h6 {
  margin-bottom: 15px; }

.wysiwyg-content p {
  margin-top: 0;
  margin-bottom: 15px; }
  .wysiwyg-content p img {
    display: inline-block; }

.cta__content {
  padding: 40px 0;
  color: #fff; }

.cta__title {
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .cta__title {
      font-size: 66px;
      font-size: 6.6rem; } }

.cta__body {
  padding-top: 10px;
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 992px) {
    .cta__body {
      font-size: 22px;
      font-size: 2.2rem; } }

.cat_link {
  color: #fff; }
  @media (min-width: 992px) {
    .cat_link {
      text-align: right; } }
  .cat_link .cat_link__box {
    padding-top: 30px;
    display: inline-block; }
    @media (min-width: 992px) {
      .cat_link .cat_link__box .btn {
        font-size: 24px;
        font-size: 2.4rem;
        padding: 20px 30px;
        line-height: 24px; } }

.blog-sidebar__widget {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px; }
  .blog-sidebar__widget .widget-header {
    padding: 5px 10px;
    background-color: #be212f; }
  .blog-sidebar__widget .widget-title {
    margin: 0;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 400;
    color: #fff; }
  .blog-sidebar__widget .widget-body .nav-list {
    margin: 0; }
    .blog-sidebar__widget .widget-body .nav-list li a {
      color: #333;
      padding-left: 38px;
      border-bottom: 1px solid rgba(51, 51, 51, 0.1); }
      .blog-sidebar__widget .widget-body .nav-list li a .fa {
        position: absolute;
        left: 10px;
        top: 14px;
        opacity: .3; }
      .blog-sidebar__widget .widget-body .nav-list li a:hover .fa, .blog-sidebar__widget .widget-body .nav-list li a:focus .fa {
        opacity: 1; }

.sidebar-post-prev__link {
  color: #333; }
  .sidebar-post-prev__link:hover, .sidebar-post-prev__link:focus {
    color: #be212f;
    text-decoration: none; }
    .sidebar-post-prev__link:hover .sidebar-post-prev__header:before, .sidebar-post-prev__link:focus .sidebar-post-prev__header:before {
      top: -30px; }

.sidebar-post-prev__header {
  background-color: #fff;
  padding: 10px 10px 30px 10px;
  position: relative; }
  .sidebar-post-prev__header:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    transition: all .3s ease-out;
    top: -20px;
    right: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 30px;
    border-color: transparent transparent #fff transparent; }

.sidebar-post-prev__title {
  font-weight: 700;
  margin: 0;
  font-size: 14px;
  font-size: 1.4rem; }

.btn {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: normal; }

.btn-bordered {
  background-color: transparent;
  border: 3px solid #333;
  color: #333; }
  .btn-bordered.btn-primary {
    border-color: #be212f;
    color: #be212f; }
    .btn-bordered.btn-primary:hover, .btn-bordered.btn-primary:focus {
      background-color: #be212f;
      color: #fff; }
  .btn-bordered.btn-white {
    border-color: #fff;
    color: #fff; }
    .btn-bordered.btn-white:hover, .btn-bordered.btn-white:focus {
      background-color: #fff;
      color: #be212f; }
  .btn-bordered:hover, .btn-bordered:focus {
    background-color: #333;
    color: #fff; }

.btn-namensko {
  background-color: transparent;
  border: 3px solid #be212f;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #be212f; }
  .btn-namensko:hover, .btn-namensko:focus {
    background-color: #be212f;
    color: #fff; }

.btn-scroll-top {
  position: fixed;
  bottom: 0;
  right: 0;
  display: block;
  visibility: hidden;
  cursor: pointer;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 50px;
  opacity: 0;
  transition: all .35s ease-in-out;
  -webkit-transition: all .35s ease-in-out;
  text-align: center;
  color: #be212f; }
  @media (min-width: 768px) {
    .btn-scroll-top {
      right: 80px; } }
  .btn-scroll-top.fixed {
    opacity: 1;
    bottom: 26px;
    visibility: visible; }

.full-bg-wrap {
  width: 100%;
  position: relative; }
  .full-bg-wrap.full-height {
    height: 100vh; }
    @media (max-width: 767px) {
      .full-bg-wrap.full-height {
        background-color: #000; } }

.full-bg__img,
.full-bg__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

.full-bg__text {
  position: relative;
  z-index: 10; }

.full-bg__img {
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover; }
  .full-bg__img.full-bg__img-fixed {
    background-attachment: fixed; }

.full-bg__overlay {
  z-index: 2;
  background: url("../img/slider-patttern.png"); }

.home-intro-sec .full-bg__img {
  background-image: url("../img/full-bg-1.jpg"); }
  @media (max-width: 767px) {
    .home-intro-sec .full-bg__img {
      background-image: url("../img/full-bg-2.jpg");
      opacity: .3; } }

.home-about-sec {
  color: #fff;
  background-color: #333; }
  .home-about-sec .full-bg__img {
    opacity: .3;
    background-image: url("../img/home-about-2.jpg"); }

.home-process-sec {
  color: #333;
  background-color: #fff; }
  .home-process-sec .full-bg__overlay {
    opacity: .3; }
  .home-process-sec .full-bg__img {
    opacity: .08;
    background-image: url("../img/home-about-2.jpg"); }

.call-to-action-idea-sec {
  background-color: #333; }
  .call-to-action-idea-sec .full-bg__img {
    opacity: .3;
    background-image: url("../img/cta-bg-1.jpg"); }

.contact-info-sec {
  background-color: #be212f; }
  .contact-info-sec .full-bg__img {
    opacity: .15;
    background-image: url("../img/cta-bg-1.jpg"); }

.service-prev {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: 30px; }

.service-prev__link {
  text-decoration: none;
  color: #333; }
  .service-prev__link:hover, .service-prev__link:focus {
    text-decoration: none; }
    .service-prev__link:hover .service-prev__title:after, .service-prev__link:focus .service-prev__title:after {
      width: 200px; }
    .service-prev__link:hover .service-prev__icon svg .a, .service-prev__link:focus .service-prev__icon svg .a {
      fill-opacity: 1; }

.service-prev__icon {
  padding-bottom: 10px;
  padding-top: 30px;
  text-align: center; }
  .service-prev__icon .svg-wrap {
    height: 100px;
    display: inline-block; }
  .service-prev__icon svg {
    width: 100%;
    height: 100%; }
    .service-prev__icon svg .a {
      fill-opacity: .3;
      transition: all 0.3s ease-out; }
  .service-prev__icon .service-web-wrap {
    width: 244px; }
  .service-prev__icon .service-soc-wrap {
    width: 247px; }
  .service-prev__icon .service-app-wrap {
    width: 162px; }
  .service-prev__icon .service-seo-wrap {
    width: 213px; }
  @media (min-width: 768px) {
    .service-prev__icon .svg-wrap {
      height: 120px; } }
  @media (min-width: 992px) {
    .service-prev__icon .svg-wrap {
      height: 160px; } }
  @media (min-width: 1200px) {
    .service-prev__icon .svg-wrap {
      height: 180px; } }
  @media (min-width: 1600px) {
    .service-prev__icon .svg-wrap {
      height: 200px; } }

.service-prev__header {
  padding: 0 20px 20px 20px; }
  @media (min-width: 1200px) {
    .service-prev__header {
      height: 68px; }
      .service-prev__header .service-prev__title {
        font-size: 19px;
        font-size: 1.9rem; } }
  @media (min-width: 1400px) {
    .service-prev__header {
      height: 78px; }
      .service-prev__header .service-prev__title {
        font-size: 24px;
        font-size: 2.4rem; } }
  @media (min-width: 1600px) {
    .service-prev__header {
      height: 82px; }
      .service-prev__header .service-prev__title {
        font-size: 26px;
        font-size: 2.6rem; } }

.service-prev__title {
  display: inline-block;
  position: relative;
  line-height: 1.2;
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: 400; }
  .service-prev__title:after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: #333;
    position: absolute;
    left: 0;
    bottom: -5px;
    transition: all 0.3s ease-out; }

.service-prev__body {
  padding: 0 20px 40px 20px; }

.blog-prev {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px; }

.blog-prev__link {
  text-decoration: none;
  color: #333; }
  @media (min-width: 768px) {
    .blog-prev__link:hover .img-mask-wrap {
      right: -30px; }
    .blog-prev__link:hover .blog-prev__text {
      padding-left: 30px;
      padding-right: 30px; } }

.blog-prev__title {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 300; }
  @media (min-width: 992px) {
    .blog-prev__title {
      font-size: 20px;
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .blog-prev__title {
      font-size: 24px;
      font-size: 2.4rem; } }

.blog-prev__img,
.blog-prev__text {
  float: left; }

.blog-prev__text {
  height: 150px;
  padding: 0px 60px 60px 0px;
  background-color: #fff;
  width: calc(100% - 160px);
  transition: all 0.3s ease-out; }
  @media (max-width: 479px) {
    .blog-prev__text {
      padding: 0px 20px 20px 0px; } }
  @media (min-width: 992px) {
    .blog-prev__text {
      width: calc(100% - 180px);
      height: 250px; } }
  @media (min-width: 1600px) {
    .blog-prev__text {
      width: calc(100% - 390px);
      height: 300px;
      padding: 0px 60px 60px 0px; } }

.blog-prev__text-inner {
  overflow: hidden;
  height: 65px; }
  @media (max-width: 479px) {
    .blog-prev__text-inner {
      display: none; } }
  @media (min-width: 992px) {
    .blog-prev__text-inner {
      height: 90px; } }
  @media (min-width: 1600px) {
    .blog-prev__text-inner {
      height: 110px; } }

.blog-prev__decor {
  display: none;
  color: #be212f;
  text-align: right;
  font-size: 30px; }
  @media (min-width: 992px) {
    .blog-prev__decor {
      display: block; } }
  @media (min-width: 1600px) {
    .blog-prev__decor {
      padding-top: 10px; } }

.blog-prev__img {
  height: 150px;
  width: 160px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 992px) {
    .blog-prev__img {
      height: 250px;
      width: 180px; } }
  @media (min-width: 1600px) {
    .blog-prev__img {
      height: 300px;
      width: 390px; } }

.img-mask-wrap {
  position: absolute;
  height: 100%;
  right: 0;
  z-index: 50;
  width: 50px;
  transition: all 0.3s ease-out; }
  .img-mask-wrap:before {
    content: '';
    width: 30px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    right: 0; }
  @media (min-width: 992px) {
    .img-mask-wrap {
      width: 60px; }
      .img-mask-wrap:before {
        width: 30px; } }
  @media (min-width: 1600px) {
    .img-mask-wrap {
      width: 90px; }
      .img-mask-wrap:before {
        width: 30px; } }

.img-mask {
  position: relative;
  width: 20px;
  height: 100%; }
  .img-mask .triangle-mask {
    position: absolute; }
  .img-mask .triangle-topright {
    top: 50%;
    margin-top: -20px;
    width: 0;
    height: 0;
    border-top: 20px solid #fff;
    border-left: 20px solid transparent; }
  .img-mask .triangle-bottomright {
    bottom: 50%;
    margin-bottom: -20px;
    width: 0;
    height: 0;
    border-bottom: 20px solid #fff;
    border-left: 20px solid transparent; }
  .img-mask:before, .img-mask:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    height: 55px;
    width: 20px;
    background-color: #fff; }
  .img-mask:before {
    top: 0; }
  .img-mask:after {
    bottom: 0; }
  @media (min-width: 992px) {
    .img-mask {
      width: 30px; }
      .img-mask .triangle-topright {
        margin-top: -30px;
        border-top: 30px solid #fff;
        border-left: 30px solid transparent; }
      .img-mask .triangle-bottomright {
        margin-bottom: -30px;
        border-bottom: 30px solid #fff;
        border-left: 30px solid transparent; }
      .img-mask:before, .img-mask:after {
        height: 95px;
        width: 30px; } }
  @media (min-width: 1600px) {
    .img-mask {
      width: 60px; }
      .img-mask .triangle-topright {
        margin-top: -60px;
        border-top: 60px solid #fff;
        border-left: 60px solid transparent; }
      .img-mask .triangle-bottomright {
        margin-bottom: -60px;
        border-bottom: 60px solid #fff;
        border-left: 60px solid transparent; }
      .img-mask:before, .img-mask:after {
        height: 90px;
        width: 60px; } }

.blog-meta .media-left {
  padding-right: 20px; }
  .blog-meta .media-left .media-object {
    width: 150px;
    background-color: #fff;
    border: 1px solid #cecece; }

.blog-meta .media-body {
  overflow: visible;
  position: relative;
  width: auto;
  vertical-align: middle;
  padding: 0 40px;
  border: 1px solid #cecece; }
  .blog-meta .media-body .autor-opis-arrow-left-1,
  .blog-meta .media-body .autor-opis-arrow-left-2 {
    width: 0;
    height: 0;
    position: absolute; }
  .blog-meta .media-body .autor-opis-arrow-left-1 {
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 16px solid #cecece;
    top: 20px;
    left: -16px;
    z-index: 5; }
  .blog-meta .media-body .autor-opis-arrow-left-2 {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    top: 21px;
    left: -14px;
    z-index: 10; }

.blog-meta .blog-meta-text .author-job {
  font-weight: 700;
  opacity: .7; }

.blog-meta .blog-meta-text .author-edu {
  opacity: .7; }

.blog-meta .blog-meta-text p {
  margin: 0; }

.blog-prev__date {
  text-align: center;
  background-color: #be212f;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  border-bottom-right-radius: 40px;
  padding: 18px 9px 0 0px; }

.page__blog-single .blog-prev__date {
  padding-top: 15px;
  height: 95px; }

.blog-prev__day {
  line-height: 1;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 900; }

.blog-prev__year {
  margin-top: 5px;
  line-height: 1;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 900; }

.blog-prev__author {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top-right-radius: 15px;
  background-color: #333;
  color: #fff;
  line-height: 15px;
  font-size: 12px;
  font-size: 1.2rem;
  padding: 3px 15px 3px 6px; }

.page-intro-sec .full-bg-wrap {
  height: auto; }
  @media (min-width: 768px) {
    .page-intro-sec .full-bg-wrap {
      height: 180px; } }
  @media (min-width: 992px) {
    .page-intro-sec .full-bg-wrap {
      height: 340px; } }

.page-intro-sec .full-bg__img {
  background-image: url(../img/full-bg-1.jpg); }

.page-intro__logo {
  padding: 20px;
  position: relative;
  z-index: 60;
  margin-right: 100px;
  display: none; }
  @media (min-width: 992px) {
    .page-intro__logo {
      display: block;
      position: absolute;
      top: 20px;
      left: 0;
      padding: 30px 60px;
      background-color: rgba(51, 51, 51, 0.9); } }

.page-intro__text {
  padding: 60px 20px 20px 20px;
  position: relative;
  width: 100%;
  z-index: 50; }
  @media (min-width: 992px) {
    .page-intro__text {
      position: absolute;
      bottom: 30px;
      padding: 0 20px 0 60px; } }
  .page-intro__text .page-type_name {
    font-size: 32px;
    font-size: 3.2rem;
    font-weight: 300; }

.tim-single {
  float: left;
  width: 50%;
  padding: 20px; }
  @media (min-width: 768px) {
    .tim-single {
      width: 33.33%; } }
  @media (min-width: 992px) {
    .tim-single {
      width: 50%; } }
  @media (min-width: 1400px) {
    .tim-single {
      width: 33.33%; } }
  .tim-single .inner {
    position: relative;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding-bottom: 78px; }
    @media (min-width: 400px) {
      .tim-single .inner {
        padding-bottom: 100px; } }
    @media (min-width: 768px) {
      .tim-single .inner {
        padding-bottom: 78px; } }
    @media (min-width: 992px) {
      .tim-single .inner {
        padding-bottom: 100px; } }
    @media (min-width: 1200px) {
      .tim-single .inner {
        padding-bottom: 78px; } }
    @media (min-width: 1400px) {
      .tim-single .inner {
        padding-bottom: 100px; } }
    @media (min-width: 1600px) {
      .tim-single .inner {
        padding-bottom: 78px; } }
  .tim-single.last .tim-single__text {
    height: auto; }
  .tim-single.last .tim-single__job {
    display: block; }
  @media (max-width: 479px) {
    .tim-single.last {
      width: 100%; } }

.tim-single__text {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  height: 78px; }
  @media (min-width: 400px) {
    .tim-single__text {
      height: 100px; } }
  @media (min-width: 768px) {
    .tim-single__text {
      height: 78px; } }
  @media (min-width: 992px) {
    .tim-single__text {
      height: 100px; } }
  @media (min-width: 1200px) {
    .tim-single__text {
      height: 78px; } }
  @media (min-width: 1400px) {
    .tim-single__text {
      height: 100px; } }
  @media (min-width: 1600px) {
    .tim-single__text {
      height: 78px; } }

.tim-single__img {
  position: relative; }
  .tim-single__img img {
    margin: 0 auto; }
  .tim-single__img .job-btn-wrap {
    position: absolute;
    text-align: center;
    height: 50px;
    width: 100%;
    bottom: 20px; }

.tim-single__name {
  padding: 15px 15px 2px 15px; }

.tim-single__job {
  display: none;
  padding: 2px 15px 15px 15px;
  font-weight: 700; }
  @media (min-width: 400px) {
    .tim-single__job {
      display: block; } }

.form-control {
  height: 46px;
  padding: 10px 12px; }
  .form-control option {
    line-height: 32px;
    font-size: 18px; }

.upitnik-header {
  padding: 20px; }

.upitnik-body {
  padding: 20px;
  background-color: #efefef; }

label {
  font-family: "Raleway", sans-serif; }

.in-form-desc {
  opacity: .6; }

.form-desc {
  opacity: .8; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: #fff;
  background-color: #be212f;
  border-color: #be212f;
  cursor: default; }

.pagination > li > a, .pagination > li > span {
  color: #333; }

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #fff;
  background-color: #333;
  border-color: #333; }
