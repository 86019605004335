.page-footer {
    border-top: 3px solid #181818;
    background-color: $text_c;
    color: #fff;
    background-image: url("../img/page-bg-pattern-10.png");

}

/*
    Footer part -- footer-top
 **********************************************************************************************************************/
.footer-top {
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer__title {
    @include font-size(24);
    font-weight: 700;
    line-height: 1.1;
}

/*
    footer_col
 **********************************************************************************************************************/
.footer_col {
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    width: 100%;
    &.footer_col-newsletter {

    }
    &.footer_col-nav {

    }
    &.footer_col-soc {

    }

    @include breakpoint($min480) {

    }
    @include breakpoint($min768) {
        &.footer_col-nav {
            //width: 500px;

        }
    }
    @include breakpoint($min992) {
        //width: 930px;
    }
    @include breakpoint($min1200) {
        //width: 1130px;
        &.footer_col-newsletter {
            //width: 580px;
            //background-color: green;
        }
        &.footer_col-nav {
            //width: 500px;
            //background-color: red;
        }
        &.footer_col-soc {
            float: none;
            //width: 510px;
            //margin: 0 auto;
            //background-color: cornflowerblue;
        }
    }
}

/*
    Footer part -- footer-top
    footer-newsletter
 **********************************************************************************************************************/
.footer-newsletter{
    margin-bottom: 20px;
}
.footer-newsletter__form {
    //max-width: 500px;
    position: relative;
    input[type=text] {
        display: inline-block;
        height: 40px;
        font-size: 13px;
        background-color: #fff;
        width: 100%;
        padding: 10px 50px 10px 10px;
        color: #333;
    }
    button {
        width: 40px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $red_c;
        display: inline-block;
        height: 40px;
        font-size: 13px;
    }
}

/*
    Footer part -- footer-top
    footer-nav
 **********************************************************************************************************************/
.footer-nav__wrap {
    @extend .clearfix;
    text-align: center;
}

.footer-nav {
    list-style-type: square;
    color: $red_c;

    text-align: left;
    padding-left: 20px;
    margin: 0;
    @include breakpoint($min768) {
        display: block;


        //&.first {
        //    width: 160px;
        //    margin-right: 20px;
        //}
        //&.second {
        //    width: 160px;
        //    margin-right: 20px;
        //}
        //&.last {
        //    width: 110px;
        //}
    }
    @include breakpoint($min1200) {



    }
    li {
        float: left;
        width: 20%;
        a {
            color: #fff;
            @include font-size(14);
        }
    }
}
.mobile-footer-nav{
    margin: 0;
    padding: 0;
    list-style: none;
	text-align: center;
    li{
        display: inline-block;
        &:after{
            content: '|';
            padding: 0 10px ;
            display: inline-block;
        }
        &:last-child{
            &:after{
                content: '';
                padding: 0;
            }
        }
        a{
            color: #fff;
            @include font-size(14);
        }
    }
}
/*
    Footer part -- footer-top
    footer-soc
 **********************************************************************************************************************/
.footer-soc {
    @include breakpoint($min1200) {
        //width: 240px;
        //float: right;
    }
    text-align: center;
    @extend .clearfix;
    a {

        width: 16.6%;
        display: block;
        float: left;
        color: $text_c;
        text-align: center;
        height: 30px;
        line-height: 30px;
        @include font-size(20);
        text-shadow: 2px 2px 0 $red_c;
        &:hover, &:focus {
            color: darken($text_c, 5%);
        }
        @include breakpoint($min400) {
            height: 40px;
            line-height: 40px;
            @include font-size(30);
            text-shadow: 3px 3px 0 $red_c;
        }
        @include breakpoint($min480) {
            line-height: 60px;
            height: 60px;
            @include font-size(50);
        }
        @include breakpoint($min768) {

            //width: 33.3%;
        }
        @include breakpoint($min1200) {
            width: 33.3%;
            height: 140px;
            line-height: 140px;
            @include font-size(140);
        }
    }
}

/*
    Footer part -- address-list
 **********************************************************************************************************************/
.address-list__wrap {
    background-color: lighten($text_c, 5%);
    //background-image: url("../img/page-bg-pattern-10.png");
    padding: 8px 0;
    text-align: center;
    font-size: 0;
}

.address-list {
    display: inline-block;
    line-height: 0;
    @extend .clearfix;

    li {
        @include font-size(14);
        text-align: left;
        display: block;
        float: left;

        .fa {
            text-align: center;
            width: 20px;
            margin-right: 2px;
        }
        span {
            display: inline-block;
            a {
                color: $red_c;
            }
        }
        &.address-list__text-wrap {
            line-height: 20px;
            padding: 9px 0;
            margin-right: 35px;
        }

        &.address-list__btn-wrap {
            .btn {
                @include font-size(14);
            }

        }
    }
}

/*
    Footer part -- footer-copy
 **********************************************************************************************************************/
.footer-copy {
    color: #797274;
    @include font-size(14);
    @include breakpoint($min768) {
        text-align: right;
    }

    //background-color: #181818;
    background-color: darken($text_c, 5%);
    background-image: url("../img/page-bg-pattern-10.png");
    padding: 10px 0;
}