.blog-sidebar {

}

.blog-sidebar__widget {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    margin-bottom: 50px;
    .widget-header {
        padding: 5px 10px;
        background-color: $red_c;
    }
    .widget-title {
        margin: 0;
        @include font-size(18);
        font-weight: 400;
        color: #fff;
    }
    .widget-body {
        .nav-list {
            margin: 0;
            li {

                a {
                    color: $text_c;
                    padding-left: 38px;
                    border-bottom: 1px solid rgba($text_c, .1);
                    .fa {
                        position: absolute;
                        left: 10px;
                        top: 14px;
                        opacity: .3;
                    }
                    &:hover, &:focus {
                        .fa {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.sidebar-post-prev {

}
.sidebar-post-prev__link{
    color: $text_c;
    &:hover,&:focus{
        color: $red_c;
        text-decoration: none;
        .sidebar-post-prev__header{
            &:before{
                top: -30px;
            }
        }
    }
}
.sidebar-post-prev__header {
    background-color: #fff;
    padding: 10px 10px 30px 10px ;
    position: relative;
    &:before{
        content: '';
        display: block;
        position: absolute;
        z-index:5;
        transition: all .3s ease-out;

        top: -20px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 30px 30px 30px;
        border-color: transparent transparent #fff transparent;
    }
}

.sidebar-post-prev__title {
    font-weight: 700;
    margin: 0;
    @include font-size(14);
}