
.services-single-content__col-icon {
	display: none;
	@include breakpoint($min768) {
		display: block;
		padding:80px 10px 10px 10px;
	}
	@include breakpoint($min992) {
		padding:80px 20px 20px 20px;
	}
	@include breakpoint($min1200) {
		padding:80px 40px 40px 40px;
	}


	.services-single__icon {

	}
	.svg-wrap {

		svg {
			width: 100%;
			height: auto;
			.a {
				fill: rgba($text_c, .2);
			}
		}
	}
}

.services-single-content__col-text {
	position: relative;
	padding-top: 40px;
	padding-right: 20px;
	@include breakpoint($min768) {
		padding-top: 80px;
		padding-right: 80px;
	}
	.page-sec__body {
		position: relative;
		z-index: 5;
	}
}

.single__icon-bg {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	opacity: .08;
	z-index: 1;
	@include breakpoint($min768) {
		display: none;
	}
}