.service-prev {
	//background-image: url("../img/slider-patttern-50.png");
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	margin-bottom: 30px;
}

.service-prev__link {
	text-decoration: none;
	color: $text_c;
	&:hover,&:focus{
		text-decoration: none;
		.service-prev__title:after{
			width: 200px;
		}
		.service-prev__icon{
			svg {
				.a {
					fill-opacity: 1;
				}
			}
		}
	}
}

.service-prev__icon {
	padding-bottom: 10px;
	padding-top: 30px;
	text-align: center;

	.svg-wrap {
		height: 100px;
		display: inline-block;
	}
	svg {
		width: 100%;
		height: 100%;
		.a {
			fill-opacity: .3;
			transition:$blog_transition;
		}
	}
	.service-web-wrap {
		width: 244px;
	}
	.service-soc-wrap {
		width: 247px;
	}
	.service-app-wrap {
		width: 162px;
	}
	.service-seo-wrap {
		width: 213px;
	}
	@include breakpoint($min768) {
		.svg-wrap {
			height: 120px;
		}
	}
	@include breakpoint($min992) {
		.svg-wrap {
			height: 160px;
		}
	}
	@include breakpoint($min1200) {
		.svg-wrap {
			height: 180px;
		}
	}
	@include breakpoint($min1600) {
		.svg-wrap {
			height: 200px;
		}
	}

}

.service-prev__header {
	padding: 0 20px 20px 20px;
	@include breakpoint($min1200) {
		.service-prev__title{
			@include font-size(19);
		}
		height: 68px;
	}
	@include breakpoint($min1400) {
		.service-prev__title{
			@include font-size(24);
		}
		height: 78px;
	}
	@include breakpoint($min1600) {
		.service-prev__title{
			@include font-size(26);
		}
		height: 82px;
	}

}

.service-prev__title {
	display: inline-block;
	position: relative;
	line-height: 1.2;
	@include font-size(26);
	font-weight: 400;
	&:after {
		content: '';
		width: 50px;
		height: 3px;
		background-color: $text_c;
		position: absolute;
		left: 0;
		bottom: -5px;
		transition:$blog_transition;
	}

}

.service-prev__body {
	padding: 0 20px 40px 20px;
}