.page-wrap {
	background-image: url("../img/page-bg-2.png");
	overflow: hidden;
	position: relative;
}

.page-sec-mrg > .inner {
	@include breakpoint($min992) {
		margin-right: $page_sec_mrg;
		margin-left: 60px;
	}
	.container {
		@include breakpoint($min768) {
			width: 750px - $page_sec_mrg;
		}
		@include breakpoint($min992) {
			width: 970px - $page_sec_mrg;
		}
		@include breakpoint($min1200) {
			width: 1170px - $page_sec_mrg;
		}

	}

}

.home-about-sec,
.home-process-sec,
.call-to-action-idea-sec,
.contact-info-sec,
.page-footer {
	&.page-sec-mrg > .inner {
		@include breakpoint($min992) {

			margin-left: 0;
		}
	}
}

.svg-wrap {
	svg {
		max-width: 100%;
		height: auto;
		text {
			font-family: $font_primary;
			font-weight: 900;
			//fill: #fff;
		}
	}
}

.page-sub-sec {
	padding-bottom: 20px;
	@include breakpoint($min768) {
		padding: 60px 40px;
	}
}

.page-sec__header {
	margin-bottom: 30px;
	&.center-page-sec__header {
		text-align: center;
	}
}

.page-sub-sec__header {
	margin-bottom: 30px;

}

.namenska-resenja-content-sec {
	padding-bottom: 60px;
}

/***********************************************************************************************************************
Single blog
***********************************************************************************************************************/
.page__blog-single {

	.blog-sec__header,
	.wysiwyg-content {
		max-width: 800px;
	}
}

.blog-sec__header {
	padding-bottom: 20px;
	.blog-img {
		position: relative;
		padding-bottom: 20px;
	}
}

.blog-meta {
	a {
		color: $red_c;
		font-weight: 700;
	}
}

.disqus-wrap {
	padding: 30px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
	margin-bottom: 50px;
}

.disqus-title {
	line-height: 1.2;
	margin-bottom: 20px;
	text-align: left;
	@include font-size(18);
	font-weight: 900;
	@include breakpoint($min992) {
		@include font-size(30);
	}
}

.blog-nav__title {
	line-height: 1.2;
	margin-bottom: 20px;
	text-align: left;
	@include font-size(18);
	font-weight: 900;
	@include breakpoint($min992) {
		@include font-size(30);
	}
}

.grid-sizer,
.grid-item {
	width: 100%;
}

.grid-item--width2 {
	width: 100%;
}

@include breakpoint($min480) {
	.grid-sizer,
	.grid-item {
		width: 50%;
	}
	.grid-item--width2 {
		width: 50%;
	}
}

@include breakpoint($min1200) {
	.grid-sizer,
	.grid-item {
		width: 33.33%;
	}
	.grid-item--width2 {
		width: 30%;
	}
}

.ref-item {
	.inner {
		padding: 20px;

	}
}

a.ref-item__link {
	&:hover, &:focus {
		.ref-item__img {
			img {
				@include breakpoint($min992) {
					opacity: .3;
				}

			}
		}
		.ref-item__header {
			@include breakpoint($min992) {
				left: 0;
			}

		}
	}
}

.ref-item__img {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
	background-color: #fff;
	position: relative;
	overflow: hidden;
	border: 1px solid #cecece;
	img {
		transition: .35s ease-in-out;
	}
}

.ref-item__header {
	background-color: $text_c;
	padding: 10px;
	color: #fff;

	@include font-size(14);
	@include breakpoint($min992) {

	}
	@include breakpoint($min1200) {

		position: absolute;
		
		//left: 0;
		@include font-size(16);
		//bottom: auto;
		padding: 20px;
		top: 50%;
		left: -100%;
	}

	z-index: 5;
	transition: .35s ease-in-out;
}

.grid-item--height2 {
	//.ref-item__header{
	//    top: 50%;
	//    bottom:auto;
	//}
}

.ref-item__title-short {
	display: block;
	@include breakpoint($min992) {
		display: none;
	}
}

.ref-item__title {
	display: none;
	@include breakpoint($min992) {
		display: block;
	}
}

.ref-img {
	margin-top: 30px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
	margin-bottom: 30px;
}

/***********************************************************************************************************************
breadcrumb
***********************************************************************************************************************/
.breadcrumb-wrap {
	background-color: #f5f5f5;
	padding: 8px 15px;
	.breadcrumb {
		padding: 0;
		margin: 0;
	}
}

/***********************************************************************************************************************
About page
***********************************************************************************************************************/

.half-page-content {
	padding-top: 60px;
	padding-bottom: 60px;

	@include breakpoint($min768) {
		&.left {
			padding-left: 40px;
		}
	}
}

.video-popup-link {
	display: inline-block;
	position: relative;
	.fa {
		position: absolute;
		top: 50%;
		margin-top: -40px;
		left: 50%;
		margin-left: -40px;
		width: 80px;
		height: 80px;
		font-size: 80px;
		line-height: 80px;
		text-align: center;
		color: $red_c;
	}
}

/***********************************************************************************************************************
Contact page
***********************************************************************************************************************/
.contact-info-sec {
	background-color: rgba($red_c, .9);
	color: #fff;
	.page-sub-sec {
		@include breakpoint($min768) {
			padding: 50px 40px 30px 40px;
		}
	}
	.page-sec__header {
		@include breakpoint($max992) {
			padding-top: 30px;
		}
	}
	.page-sub-sec__title {
		@include font-size(32);
	}
}

.map-holder {
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	background-color: #fff;
	padding: 10px;
	.map {
		border: 1px solid #dfdfdf;
	}
}

.contact-info__group {
	text-align: center;
	.inner {
		display: inline-block;
		//border-right: 1px solid $red_c;
		//border-left: 1px solid $red_c;
	}

}

.contact-info__col {
	text-align: left;

	display: block;
	margin-bottom: 40px;
	@include breakpoint($min480) {
		display: inline-block;
		width: 40%;
	}
	@include breakpoint($min768) {

	}
	@include breakpoint($min992) {
		padding: 30px;
		margin-bottom: 0;

	}
	@include breakpoint($min1200) {
		padding: 30px;
		width: auto;
		border-right: 1px solid rgba(#fff, .4);
	}
	//margin: 30px  0px 30px 30px;

	&:last-child {
		border-right: none;
		//padding-right: 0;
		//margin-right: 0;
	}

	.fa {
		width: 26px;
		text-align: center;
		padding-right: 10px;
		color: rgba(#fff, .8);
	}
	p {
		margin-bottom: 5px;
	}
}

.contact-info__header {
	height: 56px;
}

.contact-info__title {
	@include font-size(24);
}

.contact-info__name {
	@include font-size(16);
	margin-top: 7px;
}

.contact-info__tel {

}

.contact-info__mail {

}

/***********************************************************************************************************************
Upitnik page
***********************************************************************************************************************/
.page-upitnik-sec {
	padding-bottom: 60px;
}

/***********************************************************************************************************************
404 page
***********************************************************************************************************************/
.page__404__icon {
	text-align: center;
	img {
		display: inline-block;
	}
}
.text-404{
	@include font-size(24);
}