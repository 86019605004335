body {
	position: relative;
	overflow-x: hidden;
}

body,
html {
	height: 100%;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: transparent;
}

/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/

#wrapper {
	padding-left: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

#wrapper.toggled {
	//padding-right: 220px;
}

#sidebar-wrapper {
	display: none;
	@include breakpoint($min992) {
		display: block;

	}
	z-index: 1000;
	left: auto;
	right: -200px;
	width: 60px;
	width: 260px;
	height: 100%;
	//@include breakpoint($min768) {
	//
	//
	//}

	margin-left: -220px;
	overflow-y: auto;
	overflow-x: hidden;
	background: #1a1a1a;
	background: $red_c;
	transition: all 0.5s ease;
	border: none;
	&.is-open {
		height: 100%;
		min-height: 480px;
		position: absolute;
		position: fixed;
		@include breakpoint($min768) {

		}
	}
	&:hover, &:focus {
		width: 280px;
		right: 0;
	}
}

#sidebar-wrapper::-webkit-scrollbar {
	display: none;
}

//#wrapper.toggled #sidebar-wrapper {
//	width: 220px;
//}

#page-content-wrapper {
	width: 100%;
	padding-top: 70px;
}

#wrapper.toggled #page-content-wrapper {
	position: absolute;
	margin-right: -220px;

}

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/

#sidebar-wrapper {
	.nav-right-menu {
		background-color: $red_c;
		width: 100%;
		position: absolute;
		z-index: 70;
		top: 80px;
		left: 0;

		margin: 0;
		padding: 0;
		list-style: none;
		.dropdown-menu > li > a {
			white-space: normal;
		}
	}
	&.is-open {
		.nav-right-menu {
			left: 0;
		}

	}
	&.is-closed {
		.nav-right-menu {
			//opacity: 0;
		}

	}
}

.sidebar-nav li {
	position: relative;
	line-height: 20px;
	display: block;
	width: 100%;
}

@include breakpoint($min768) {
	.sidebar-nav li:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		height: 100%;
		width: 0px;
		background-color: #1c1c1c;

		transition: all .2s ease-in-out;
		opacity: 0;

	}
	.sidebar-nav li:hover:before,
	.sidebar-nav li.open:hover:before {
		opacity: 1;
		width: 100%;
		//-webkit-transition: width .2s ease-in;
		//-moz-transition: width .2s ease-in;
		//-ms-transition: width .2s ease-in;
		//transition: width .2s ease-in;

	}
	.sidebar-nav li a:hover,
	.sidebar-nav li a:active,
	.sidebar-nav li a:focus,
	.sidebar-nav li.open a:hover,
	.sidebar-nav li.open a:active,
	.sidebar-nav li.open a:focus {
		color: #fff;
		text-decoration: none;
		background-color: transparent;
	}
}

.sidebar-nav li a {
	font-family: $font_seocnd;

	display: block;
	color: #fff;
	text-decoration: none;
	padding: 12px 0 12px 0;
	border-bottom: 1px solid #d92635;
	@extend .clearfix;
	span {
		float: left;

	}
	.nav-icon {
		text-align: center;
		width: 60px;
		&.lang-nav-icon {
			font-weight: 900;
		}
	}
	.nav-link-text {
		width: 160px;
		@include font-size(13);
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	.nav-caret {
		text-align: center;
		width: 35px;
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 20px;
		&:before {
			content: "\f067";

		}
	}
}

.sidebar-nav > li.active > a {
	background-color: #1a1a1a;
}

.sidebar-nav > li.activelang > a {
	color: #ef3b4a;
	background-color: $red_c;
}

.dropdown {
	&.open {
		.nav-caret {
			&:before {
				content: "\f068";

			}
		}
	}
}

.sidebar-nav li.open {
	background-color: #1a1a1a;
}

.sidebar-nav > .sidebar-brand {
	height: 65px;
	font-size: 20px;
	line-height: 44px;
}

.sidebar-nav .dropdown-menu {
	position: relative;
	width: 100%;
	padding: 0;
	margin: 0;
	border-radius: 0;
	border: none;
	background-color: #3e3e3e;
	box-shadow: none;
	float: none;
	a {
		padding-left: 60px;
		border-bottom: 1px solid #4f4f4f;
	}
	li.active {
		a {
			background-color: #676767;
		}
	}
}

/*-------------------------------*/
/*     nav-right-menu-mobile     */
/*-------------------------------*/
#sidebar-wrapper {
	#nav-right-menu-mobile {
		background-color: $red_c;
		position: absolute;
		z-index: 70;
		top: 70px;
		left: 60px;
		width: 220px;
		margin: 0;
		padding: 0;
		list-style: none;
		.dropdown-menu > li > a {
			white-space: normal;
		}
		&.nav > li > a:focus,
		&.nav > li > a:hover {
			background-color: inherit;
			outline: none;
		}

	}
	&.is-open {
		#nav-right-menu-mobile {
			left: 0;
		}

	}
	&.is-closed {
		#nav-right-menu-mobile {
			opacity: 0;
		}

	}
	.page-social__links-mobile {
		a {
			display: inline-block;
			width: 33px;
			text-align: center;
			padding: 10px 0;
		}
	}

}

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
	position: relative;
	right: 0;
	top: 0;
	z-index: 999;
	display: block;
	width: 100%;
	height: 60px;
	@include breakpoint($min768) {
		height: 80px;
	}
	//margin-left: 15px;
	background: #e52737;
	border: none;

	#hamburger-hamb-wrap {
		position: absolute;
		left: 15px;
		top: 15px;
		display: block;
		width: 30px;
		height: 32px;
		transition: all .35s ease-in-out;
		@include breakpoint($min768) {
			top: 25px;
		}
	}
	&.is-open {
		right: 0;
		#hamburger-hamb-wrap {
			right: 92px;
		}
	}
}

.hamburger-img {
	width: 260px;
	padding-left: 80px;
	padding-right: 30px;
	padding-top: 25px;
}

.hamburger:hover,
.hamburger:focus,
.hamburger:active {
	outline: none;
	//cursor: pointer;
}

.hamburger.is-closed:before {
	content: '';
	display: block;
	width: 100px;
	font-size: 14px;
	color: #fff;
	line-height: 32px;
	text-align: center;
	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transition: all .35s ease-in-out;
}

@include breakpoint($min768) {
	//.hamburger.is-closed:hover:before {
	//	opacity: 1;
	//	display: block;
	//	-webkit-transform: translate3d(-100px, 0, 0);
	//	-webkit-transition: all .35s ease-in-out;
	//}
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
	position: absolute;
	left: 0;
	height: 4px;
	width: 100%;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
	background-color: #fff;
}

.hamburger.is-closed .hamb-top {
	top: 5px;
	-webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-middle {
	top: 50%;
	margin-top: -2px;
}

.hamburger.is-closed .hamb-bottom {
	bottom: 5px;
	-webkit-transition: all .35s ease-in-out;
}

@include breakpoint($min768) {
	//.hamburger.is-closed:hover .hamb-top {
	//	top: 0;
	//	-webkit-transition: all .35s ease-in-out;
	//}
	//
	//.hamburger.is-closed:hover .hamb-bottom {
	//	bottom: 0;
	//	-webkit-transition: all .35s ease-in-out;
	//}
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
	background-color: #fff;
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
	top: 50%;
	margin-top: -2px;
}

.hamburger.is-open .hamb-top {
	-webkit-transform: rotate(45deg);
	-webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
}

.hamburger.is-open .hamb-middle {
	display: none;
}

.hamburger.is-open .hamb-bottom {
	-webkit-transform: rotate(-45deg);
	-webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
}

.hamburger.is-open:before {
	content: '';
	display: block;
	width: 100px;
	font-size: 14px;
	color: #fff;
	line-height: 32px;
	text-align: center;
	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-transition: all .35s ease-in-out;
}

.hamburger.is-open:hover:before {
	opacity: 1;
	display: block;
	-webkit-transform: translate3d(-100px, 0, 0);
	transition: all .35s ease-in-out;
}

/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/

.overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(250, 250, 250, .8);
	z-index: 191;
}

/*
page-social
***********************************************************************************************************************/
.page-social-wrap {

	position: static;
	padding: 20px 0;

	@include breakpoint($min768) {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 0;
	}
	width: 100%;

	z-index: 60;
	text-align: center;
	a {
		//background-color: green;
		display: block;
		color: #fff;
		@include font-size(18);
		//margin-bottom: 10px;
		padding: 5px 0;
		&:hover, &:focus {
			background-color: $text_c;
		}
	}
	@include breakpoint($min768) {
		display: block;
	}
}

.page-social__inner {
	position: relative;

	width: 100%;
	height: 100%;

	z-index: 80;
}

.page-social__links {
	position: relative;
	width: 100%;
	@extend .clearfix;
	text-align: center;
	a {
		position: absolute;
		width: 60px;
		display: inline-block;
		transition: all .2s ease-in;

		&.behance {
			left: 0;
			bottom: 175px;
		}
		&.youtube {
			left: 0;
			bottom: 140px;
		}
		&.linkedin {
			left: 0;
			bottom: 105px;
		}
		&.google {
			left: 0;
			bottom: 70px;
		}
		&.twitter {
			left: 0;
			bottom: 35px;
		}
		&.facebook {
			left: 0;
			bottom: 0;
		}
	}

}

#wrapper.toggled {
	.page-social__links {
		padding: 0 25px;
		a {
			width: 36px;
			&.behance {
				left: 0;
				bottom: 0;
			}
			&.youtube {
				left: 36px;
				bottom: 0;
			}
			&.linkedin {
				left: 72px;
				bottom: 0;
			}
			&.google {
				left: 108px;
				bottom: 0;
			}
			&.twitter {
				left: 144px;
				bottom: 0;
			}
			&.facebook {
				left: 180px;
				bottom: 0;
			}
		}
	}

}

.nav-right-menu, .page-social-wrap {
	display: none;
	@include breakpoint($min768) {
		display: block;
	}
}

.nav-right-menu-mobile {
	display: block;
	@include breakpoint($min768) {
		display: none;
	}
}

.lang-switch {
	width: 60px;
	background-color: $red_c;
	position: absolute;
	top: 60px;
	right: 0;
	z-index: 50;
	@extend .clearfix;
	.lang {
		display: block;
		width: 60px;
		height: 40px;
		text-transform: uppercase;
		float: left;
		line-height: 40px;
		text-align: center;
		color: $text_c;
		font-weight: 700;
		&.active {
			color: #fff;
			&:hover, &.focus {
				background-color: transparent;
			}
		}
		&:hover, &.focus {
			background-color: $text_c;
			color: #fff;
			text-decoration: none;
		}
	}
	&.lang-switch-mobile {
		display: block;
		@include breakpoint($min768) {
			display: none;
		}
	}
	&.lang-switch-desk {
		display: none;
		@include breakpoint($min768) {
			display: block;
			top: 80px;
		}
	}
}

.page-social__left-standalone {
	display: none;
	@include breakpoint($min992) {
		display: block;
		position: fixed;
		left: 0;
		top: 50%;
		z-index: 1000;
	}

	a {
		background-color: $red_c;
		display: block;
		width: 40px;
		height: 40px;
		margin-bottom: 2px;
		text-align: center;
		padding: 10px 0;
		@include font-size(20);
		color: #fff;
		.fa {
			display: block;
		}
		&:hover, &:focus {
			text-decoration: none;
			background-color: $text_c;
		}
	}
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
	border-color: transparent;
}

.home-intro-sec {
	@include breakpoint($max992) {
		//position: absolute;
		//top: 0;
		//bottom: 0;
		//left: 0;
		//right: 0;
	}
}

#mobile-nav {
	position: absolute;
	width: 100%;
	border: none;
	z-index: 200;
	.mobile-nav-inner {
		position: relative;
	}
	.navbar-header {
		position: absolute;
		width: 100%;
		z-index: 200;
	}
	#mobile-nav-menu {

		margin: 0;
		padding-top: 60px;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 100;
		background-color: $text_c;
		right: -110%;
		transition: all 0.2s ease-in-out;

		&.show_in {
			right: 0;
		}
	}
}

