.call-to-action-idea-sec {
	//margin-top: 50px;
	//background-image: url("../img/cta-bg-1.jpg");
	//background-repeat: no-repeat;
	//background-position: top;
	//background-size: cover;
}

.cta__content {
	padding: 40px 0;
	color: #fff;


}

.cta__header {

}

.cta__title {
	@include font-size(32);
	@include breakpoint($min992) {
		@include font-size(66);
	}
	font-weight: 300;

}

.cta__body {
	padding-top: 10px;
	@include font-size(16);
	@include breakpoint($min992) {
		@include font-size(22);
	}

}

.cat_link {
	@include breakpoint($min992) {
		text-align: right;
	}


	color: #fff;
	.cat_link__box {
		padding-top: 30px;
		display: inline-block;
		.btn {

			@include breakpoint($min992) {
				@include font-size(24);
				padding: 20px 30px;
				line-height: 24px;
			}
		}

	}
}