/*
home-intro__text
***********************************************************************************************************************/

.home-intro__text {
	position: absolute;
	bottom: 10%;
	width: 100%;
	@include breakpoint($min768) {
		padding: 0 20px 0 50px;
	}

	z-index: 50;

}

.home-intro__logo {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 60;
	//width: 50%;
	margin-right: 100px;
	a {
		display: block;
	}
	@include breakpoint($min400) {

	}
	@include breakpoint($min768) {
		top: 20px;
		left: 0;
		padding: 30px 60px;
		background-color: rgba($text_c, .9);
	}
	@include breakpoint($min992) {

	}
	@include breakpoint($min1200) {

	}
	@include breakpoint($min1600) {

	}

	@include breakpoint($max992) {
		display: none;
	}
}

/*
home-services-sec
***********************************************************************************************************************/
.home-services-sec {
	//padding-top: 15px;
}

.home-sec__header {
	padding: 15px 0;
}

/*
home-process-sec
***********************************************************************************************************************/
.home-process-sec {
	margin: 80px 0 0 0;
	.home-sec__header {
		margin-bottom: 40px;
		margin-top: 30px;
	}

}

.proces-prev-wrap {
	@extend .clearfix;
}

.proces-prev {
	width: 16.66%;
	position: relative;
	float: left;
	height: 560px;
}

.proces-img {
	width: 130%;
	position: absolute;
	left: 0;
	top: 50%;
	//transform: translate(0,-50%);
}

.proces-body {
	position: absolute;
	width: 128%;
}

.proces-title {
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.31);
	@include font-size(32);
}

.proces-prev-1,
.proces-prev-3,
.proces-prev-5 {
	.proces-body{
		top: 0;
	}
	.proces-img {
		transform: translate(0,-70%);
	}
}

.proces-prev-2,
.proces-prev-4,
.proces-prev-6 {
	.proces-body{
		bottom: 0;

	}
	.proces-img {
		transform: translate(0,-30%);
	}
}

.proces-prev-1 {
	.proces-title {
		color: #13637c;
	}

}

.proces-prev-2 {
	.proces-title {
		color: #33b7c6;
	}
}

.proces-prev-3 {
	.proces-title {
		color: #99cc33;
	}
}

.proces-prev-4 {
	.proces-title {
		color: #ffab2e;
	}
}

.proces-prev-5 {
	.proces-title {
		color: #ea4b48;
	}
}

.proces-prev-6 {
	.proces-title {
		color: #a63573;
	}
}

/*
home-about-sec
***********************************************************************************************************************/
.home-about-sec {

	margin: 0 0 80px 0;
	color: #fff;
	.full-bg-wrap > .container {
		padding-bottom: 60px;
	}
	.home-sec__header {
		margin-top: 30px;
		margin-bottom: 10px;

	}
	.full-bg__text-col {
		font-weight: 300;
		@include font-size(18);
	}

}

/*
home-blog-sec
***********************************************************************************************************************/
.home-blog-sec {
	//padding-bottom: 50px;
}