html {
	min-height: 100%;
	font-size: 62.5%;

}

body {
	font-family: $font_seocnd;
	color: $text_c;
	@include font-size(16);
	font-weight: 400;
	img {
		max-width: 100%;
		display: block;
		height: auto;
	}
	.media-object {
		max-width: none;
	}
}

a {
	color: $red_c;
	&:hover, &:focus {
		color: $text_c;
	}
}

#map {
	height: 440px;
	width: 100%;
}

svg {
	//width: 100%;
	//height: auto;
}

button {
	padding: 0;
	margin: 0;
	border: none;
	outline: 0;
	background: 0 0;
}

.list-none {
	margin: 0;
	padding: 0;
	list-style: none;
}

.container-both-pd{
	padding-right: 30px;
	padding-left: 60px;
}
.white-popup-block {
	background: #FFF;

	text-align: left;
	max-width: 650px;
	margin: 40px auto;
	position: relative;
	.modal-header {
		padding: 20px;
		position: relative;
	}
	.modal-title {
		margin: 0;

	}
	.popup-modal-dismiss {
		@include font-size(36);
		position: absolute;
		top: 8px;
		right: 20px;
		z-index: 20;
	}
}

.min-height-50 {
	min-height: 50px;
}

.apaone-word {
	font-weight: 700;
	.w-apa {
		color: lighten($text_c, 30%);
	}
	.w-one {
		color: $red_c;
	}
}